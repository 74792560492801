import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { faClockRotateLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover, Transition, PopoverButton, PopoverPanel, } from '@headlessui/react';
import { NoSymbolIcon, PlusCircleIcon } from '@heroicons/react/24/outline';
import { CheckBadgeIcon, InformationCircleIcon, } from '@heroicons/react/24/solid';
import { FormattedMessage } from 'react-intl';
import AssignProctor from '@components/AssignProctor';
import AccommodationBadges from '@components/Base/Accomodations';
import { Checkbox } from '@components/Base/Checkbox';
import IntegrityRating from '@components/Base/IntegrityRating';
import Link from '@components/Base/Link';
import Tooltip from '@components/Base/NewToolTip';
import Rating from '@components/Base/Rating';
import User from '@components/Base/UserDetails';
import { convertToTitleCase } from '@components/ProctorNgSessions/SessionTable/helper';
import messages from '@components/ProctorNgSessions/SessionTabs/messages';
import { classNames } from '@utils/classNames';
import { formatDate, formatTimeFromMinutes, getDaysAgo, } from '@utils/dateFormat';
import editIcon from '../../../../images/edit-icon.svg';
import gripHorizontalIcon from '../../../../images/grip-horizontal.svg';
export const createColumns = ({ toggleCancelProctorModal, proctors, }) => [
    {
        id: 'admin_reviews',
        header: () => '',
        accessorKey: 'reviews',
        enableSorting: false,
        cell: ({ row }) => (_jsx("div", { children: row.original.review === 'reviewed' ? (_jsx(Tooltip, { refDiv: _jsx(CheckBadgeIcon, { className: "w-5 h-5 text-blue-700" }), position: "top-middle", children: _jsx("div", { children: _jsx(FormattedMessage, Object.assign({}, messages.reviewed)) }) })) : row.original.review === 'pending' ? (_jsx(Tooltip, { refDiv: _jsx(FontAwesomeIcon, { icon: faClockRotateLeft, className: "w-4 h-4 text-blue-700" }), position: "top-middle", children: _jsx("div", { children: _jsx(FormattedMessage, Object.assign({}, messages.review_pending)) }) })) : null })),
        size: 40,
        className: 'text-center left-12 sticky',
        cellClassName: 'text-center left-12 sticky z-[1]',
    },
    {
        id: 'reviews',
        header: () => '',
        accessorKey: 'reviews',
        enableSorting: false,
        cell: ({ row }) => (_jsx("div", { children: row.original.review === 'reviewed' ? (_jsx(Tooltip, { refDiv: _jsx(CheckBadgeIcon, { className: "w-5 h-5 text-blue-700" }), position: "top-middle", children: _jsx("div", { children: _jsx(FormattedMessage, Object.assign({}, messages.reviewed)) }) })) : row.original.review === 'pending' ? (_jsx(Tooltip, { refDiv: _jsx(FontAwesomeIcon, { icon: faClockRotateLeft, className: "w-4 h-4 text-blue-700" }), position: "top-middle", children: _jsx("div", { children: _jsx(FormattedMessage, Object.assign({}, messages.review_pending)) }) })) : null })),
        size: 40,
        className: 'text-center left-0 sticky',
        cellClassName: 'text-center left-0 sticky z-[1]',
    },
    {
        id: 'session_candidate',
        header: 'Attendee',
        accessorKey: 'session_candidate',
        enableSorting: false,
        cell: ({ row }) => {
            return (_jsx(User, { data: {
                    name: '',
                    email: '',
                }, size: "sm", hideImage: true, additionalContent: row.original.session_candidate.id }));
        },
        size: 208,
        className: 'text-left text-sm font-medium font-roboto text-gray-900 left-[88px] sticky attendee-column shadow-[inset_-1px_0px_0px_0px] shadow-gray-400/20',
        cellClassName: 'text-left sticky left-[88px] attendee-column shadow-[inset_-1px_0px_0px_0px] z-[1] shadow-gray-400/20',
    },
    {
        id: 'session_candidate_proctor',
        header: 'Attendee',
        accessorKey: 'session_candidate_proctor',
        enableSorting: false,
        cell: ({ row }) => {
            return (_jsx(User, { data: {
                    name: '',
                    email: '',
                }, size: "sm", hideImage: true, additionalContent: row.original.session_candidate.id }));
        },
        size: 208,
        className: 'text-left text-sm font-medium font-roboto text-gray-900 left-10 sticky attendee-column shadow-[inset_-1px_0px_0px_0px] shadow-gray-400/20',
        cellClassName: 'text-left sticky left-10 attendee-column shadow-[inset_-1px_0px_0px_0px] z-[1] shadow-gray-400/20',
    },
    {
        id: 'due_date',
        header: 'Due Date',
        accessorKey: 'due_date',
        enableSorting: false,
        cell: ({ row }) => row.original.due_date
            ? formatDate(new Date(row.original.due_date), 'withOutTime')
            : '-',
        size: 133,
        className: 'text-left text-sm font-medium font-roboto text-gray-900',
        cellClassName: 'text-left text-sm/[19px] font-normal',
    },
    {
        id: 'exam_details',
        header: 'Exam',
        accessorKey: 'exam_details',
        enableSorting: false,
        cell: ({ row }) => (_jsx("div", { children: row.original.exam_details ? (_jsx(Link, { title: row.original.exam_details.name, description: row.original.exam_details.id })) : (_jsx(_Fragment, { children: "-" })) })),
        size: 173,
        className: 'text-left text-sm font-medium font-roboto text-gray-900',
        cellClassName: 'text-left',
    },
    {
        id: 'session_rating',
        header: () => (_jsxs("div", { className: "flex align-middle", children: [_jsx("span", { className: "mr-1", children: _jsx(FormattedMessage, Object.assign({}, messages.session_rating)) }), _jsx(Tooltip, { refDiv: _jsx(InformationCircleIcon, { className: "w-5 h-5 text-gray-400" }), position: "bottom-right", children: _jsx(IntegrityRating, {}) })] })),
        accessorKey: 'session_rating_details',
        enableSorting: false,
        cell: ({ row }) => {
            var _a, _b, _c;
            return (_jsx("div", { className: "flex align-left", children: _jsx(Rating, Object.assign({}, (((_a = row.original.session_rating_details) === null || _a === void 0 ? void 0 : _a.rating) && {
                    rating: row.original.session_rating_details.rating,
                }), (((_b = row.original.session_rating_details) === null || _b === void 0 ? void 0 : _b.status_history) && {
                    statusHistory: row.original.session_rating_details.status_history,
                }), (((_c = row.original.session_rating_details) === null || _c === void 0 ? void 0 : _c.flags) && {
                    flags: row.original.session_rating_details.flags,
                }))) }));
        },
        size: 148,
        className: 'text-left text-sm font-medium font-roboto text-gray-900',
        cellClassName: 'text-left',
    },
    {
        id: 'proctoring_type',
        header: 'Proctoring Type',
        accessorKey: 'proctoring_type',
        enableSorting: false,
        cell: ({ row }) => _jsx("div", { children: row.original.proctoring_type }),
        size: 140,
        className: 'text-left text-sm font-medium font-roboto text-gray-900',
        cellClassName: 'text-left',
    },
    {
        id: 'accomodations',
        header: 'Accomodations',
        accessorKey: 'accomodations',
        enableSorting: false,
        cell: ({ row }) => {
            return (_jsx(AccommodationBadges, { accommodations: row.original.accomodations }));
        },
        size: 262,
        className: 'text-left text-sm font-medium font-roboto text-gray-900',
        cellClassName: 'text-left',
    },
    {
        id: 'tenant',
        header: 'Tenant',
        accessorKey: 'tenant',
        enableSorting: false,
        cell: ({ row }) => _jsx("div", { children: row.original.tenant }),
        size: 140,
        className: 'text-left text-sm font-medium font-roboto text-gray-900',
        cellClassName: 'text-left text-sm/[19px] font-normal',
    },
    {
        id: 'attempt',
        header: 'Attempt',
        accessorKey: 'attempt',
        enableSorting: false,
        cell: ({ row }) => _jsx("div", { children: row.original.attempt }),
        size: 95,
        className: 'text-center text-sm font-medium font-roboto text-gray-900',
        cellClassName: 'text-center',
    },
    {
        id: 'status',
        header: 'Status',
        accessorKey: 'status',
        enableSorting: false,
        cell: ({ row }) => _jsx("div", { children: row.original.status }),
        size: 133,
        className: 'text-left text-sm font-medium font-roboto text-gray-900',
        cellClassName: 'text-left',
    },
    {
        id: 'reason',
        header: 'Reason',
        accessorKey: 'reason',
        enableSorting: false,
        cell: ({ row }) => _jsx("div", { children: row.original.reason }),
        size: 194,
        className: 'text-left text-sm font-medium font-roboto text-gray-900',
        cellClassName: 'text-left',
    },
    {
        id: 'summary',
        header: 'Summary',
        accessorKey: 'summary',
        enableSorting: false,
        cell: ({ row }) => {
            var _a, _b;
            return (_jsx(Tooltip, { refDiv: _jsx("div", { className: `overflow-hidden text-sm font-normal`, style: {
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2,
                    }, children: (_a = row.original.summary) !== null && _a !== void 0 ? _a : '-' }), position: "top-left", children: _jsx("div", { className: "w-[269px] whitespace-normal leading-tight", children: (_b = row.original.summary) !== null && _b !== void 0 ? _b : '-' }) }));
        },
        size: 237,
        className: 'text-left text-sm font-medium font-roboto text-gray-900',
        cellClassName: 'text-left',
    },
    {
        id: 'session_duration',
        header: 'Session Duration',
        accessorKey: 'session_duration',
        enableSorting: false,
        cell: ({ row }) => row.original.session_duration
            ? formatTimeFromMinutes(row.original.session_duration)
            : '-',
        size: 140,
        className: 'text-left text-sm font-medium font-roboto text-gray-900',
        cellClassName: 'text-left',
    },
    {
        id: 'external_id',
        header: 'External ID',
        accessorKey: 'external_id',
        enableSorting: false,
        cell: ({ row }) => _jsx("div", { children: row.original.external_id }),
        size: 124,
        className: 'text-left text-sm font-medium font-roboto text-gray-900',
        cellClassName: 'text-center text-sm font-normal',
    },
    {
        id: 'completed_on',
        header: 'Completed On',
        accessorKey: 'completed_on',
        enableSorting: false,
        cell: ({ row }) => row.original.completed_on
            ? formatDate(new Date(row.original.completed_on), 'withTime')
            : '-',
        size: 203,
        className: 'text-left text-sm font-medium font-roboto text-gray-900',
        cellClassName: 'text-left text-sm/[19px] font-normal',
    },
    {
        id: 'requested_on',
        header: 'Requested On',
        accessorKey: 'requested_on',
        enableSorting: false,
        cell: ({ row }) => row.original.requested_on
            ? formatDate(new Date(row.original.requested_on), 'withTime')
            : '-',
        size: 203,
        className: 'text-left text-sm font-medium font-roboto text-gray-900',
        cellClassName: 'text-left text-sm/[19px] font-normal',
    },
    {
        id: 'proctor',
        header: 'Proctor',
        accessorKey: 'proctor',
        enableSorting: false,
        cell: ({ row }) => {
            var _a, _b;
            return (_jsx(User, { data: {
                    name: row.original.proctor
                        ? `${(_a = row.original.proctor) === null || _a === void 0 ? void 0 : _a.first_name} ${(_b = row.original.proctor) === null || _b === void 0 ? void 0 : _b.last_name}`
                        : '',
                    email: '',
                }, size: "sm", hideImage: false, additionalContent: "" }));
        },
        size: 180,
        className: 'text-left text-sm font-medium font-roboto text-gray-900',
        cellClassName: 'text-center text-sm/[19px] font-normal',
    },
    {
        id: 'edit',
        header: '',
        accessorKey: 'edit',
        enableSorting: false,
        size: 47,
        cell: () => {
            const [isModalOpen, setIsModalOpen] = React.useState(false);
            const [hasInProgressSession] = React.useState(false);
            const handleCloseModal = () => {
                setIsModalOpen(false);
            };
            const handleRequestReview = () => { };
            const toggleOpenModal = () => {
                setIsModalOpen(!isModalOpen);
            };
            return (_jsxs(_Fragment, { children: [_jsx("div", { className: "w-5 h-5 cursor-pointer", "data-testid": "edit-request", onClick: toggleOpenModal, onKeyDown: (e) => {
                            if (e.key === 'Enter')
                                toggleOpenModal();
                        }, role: "button", tabIndex: 0, children: _jsx("img", { src: editIcon, alt: "edit", className: "w-full h-full" }) }), _jsx(AssignProctor, { data: proctors, headerText: "Edit Request", isEdit: true, openAddFlowModal: isModalOpen, handleCloseModal: handleCloseModal, onRequestReview: handleRequestReview, hasInProgressSession: hasInProgressSession })] }));
        },
    },
    {
        id: 'delete',
        header: '',
        accessorKey: 'delete',
        enableSorting: false,
        size: 47,
        cell: () => (_jsx("div", { children: _jsx(NoSymbolIcon, { className: "w-5 h-5 text-[#B91C1C] cursor-pointer", onClick: toggleCancelProctorModal, "data-testid": "cancel-proctor-btn" }) })),
    },
    {
        id: 'aging',
        header: 'Aging',
        accessorKey: 'aging',
        enableSorting: false,
        size: 124,
        cell: () => getDaysAgo(new Date('2024-09-26T05:55:26.996Z')),
        className: 'text-center text-sm font-medium font-roboto text-gray-900',
        cellClassName: 'text-center text-sm/[19px] font-normal pl-6',
    },
    {
        id: 'menu',
        enableSorting: false,
        accessorKey: 'menu',
        size: 48,
        className: 'text-left right-0 sticky shadow-[inset_0px_0px_0px_-1px] shadow-gray-400/20',
        cellClassName: 'text-center right-0 sticky z-[1] shadow-[inset_0px_0px_0px_-1px] shadow-gray-400/20',
        header: ({ table }) => {
            return (_jsx("div", { children: _jsxs(Popover, { className: "flex justify-end items-center pr-3", children: [_jsx(PopoverButton, { className: classNames('focus:outline-none'), children: _jsx(PlusCircleIcon, { className: classNames('text-black h-5 w-5 focus:outline-none'), "aria-hidden": "true", "data-testid": "hide-column" }) }), _jsx(Transition, { children: _jsx(PopoverPanel, { children: _jsx("div", { className: "bg-white border-solid border-[1px] w-[214px] font-normal shadow-sm max-h-[480px] overflow-y-auto rounded-md px-4 absolute right-0 top-12", "data-testid": "column-model", children: table.getAllLeafColumns().map((column) => {
                                        if (column.id === 'menu' ||
                                            column.id === 'reviews' ||
                                            column.id === 'admin_reviews' ||
                                            column.id === 'selection') {
                                            return null;
                                        }
                                        return (_jsxs("div", { className: "h-[40px] w-full flex items-center", children: [_jsx("span", { className: "pr-2 w-6 h-6", children: _jsx("img", { src: gripHorizontalIcon, alt: "edit", className: "w-full h-full" }) }), _jsx(Checkbox, { label: typeof column.columnDef.header === 'string'
                                                        ? column.columnDef.header
                                                        : convertToTitleCase(column.id), id: column.id, size: "md", checked: column.getIsVisible(), onChange: column.getToggleVisibilityHandler(), disabled: false })] }, column.id));
                                    }) }) }) })] }) }));
        },
    },
    {
        id: 'reason_for_request',
        header: 'Reason For Request',
        accessorKey: 'reason_for_request',
        enableSorting: false,
        cell: ({ row }) => {
            var _a;
            return (_jsx(Tooltip, { refDiv: _jsx("div", { className: `overflow-hidden text-sm font-normal`, style: {
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 1,
                    }, children: row.original.reason_for_request
                        ? row.original.reason_for_request
                        : '-' }), position: "top-left", children: _jsx("div", { className: "w-[269px] whitespace-normal leading-tight", children: (_a = row.original.reason_for_request) !== null && _a !== void 0 ? _a : '-' }) }));
        },
        size: 194,
        className: 'text-left text-sm font-medium font-roboto text-gray-900',
        cellClassName: 'text-left',
    },
];
