import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { TrashIcon } from '@heroicons/react/24/outline';
import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';
import DisableFlowModal from '@components/Flows/DisableFlowModal';
import { STATUS } from '@utils/data/enums';
import { formatDate } from '@utils/dateFormat';
import { handleEnterKeyPress } from '@utils/helpers';
import Badge from 'app/components/Base/Badge';
import ToggleButton from 'app/components/Base/ToggleButton';
import User from 'app/components/Base/UserDetails';
import messages from './messages';
const StatusBadge = (status) => {
    switch (status) {
        case STATUS.PUBLISHED:
            return _jsx(Badge, { type: "primary", children: status });
        case STATUS.DRAFT:
            return _jsx(Badge, { type: "draft", children: status });
        default:
            return _jsx(Badge, { type: "primary", children: status });
    }
};
const getLatestPublishConfig = (row) => {
    return row.original.flow_configs
        .filter((config) => config.status === STATUS.PUBLISHED)
        .reverse()[0];
};
export const createColumns = (updatedAtSortingFn, handleClick, handleDeleteClick, handleStatusChange) => [
    {
        header: () => _jsx(FormattedMessage, Object.assign({}, messages.flow_name)),
        accessorKey: 'flowName',
        enableSorting: false,
        cell: ({ row }) => (_jsxs("div", { onClick: () => handleClick(row.original.uuid), onKeyDown: (e) => handleEnterKeyPress(e, () => handleClick(row.original.uuid)), role: "button", tabIndex: 0, className: "px-2 font-normal w-[488px] truncate", children: [_jsx("div", { title: row.original.name, className: "text-sm truncate text-primary", children: row.original.name }), _jsx("div", { title: row.original.uuid, className: "text-xs text-gray-500 truncate", children: row.original.uuid })] })),
        size: 488,
    },
    {
        header: '',
        size: 153,
        enableSorting: false,
        minSize: 153,
        maxSize: 153,
        accessorKey: 'blank',
        cell: ({ row }) => {
            const latestPublishedFlowConfig = getLatestPublishConfig(row);
            return row.original.is_default ? (_jsx(Badge, { type: "primary", rounded: "md", children: _jsx(FormattedMessage, Object.assign({}, messages.default)) })) : (StatusBadge((latestPublishedFlowConfig === null || latestPublishedFlowConfig === void 0 ? void 0 : latestPublishedFlowConfig.status) ||
                row.original.flow_configs[0].status ||
                ''));
        },
    },
    {
        header: () => _jsx(FormattedMessage, Object.assign({}, messages.status)),
        size: 153,
        enableSorting: false,
        minSize: 153,
        maxSize: 153,
        accessorKey: 'status',
        cell: ({ row }) => {
            const [isActive, setIsActive] = useState(row.original.is_active);
            const [showModal, setShowModal] = useState(false);
            const [togglePayload, setTogglePayload] = useState({
                id: null,
                active: false,
            });
            const handleUpdateStatus = (id, active) => {
                if (!active) {
                    handleMakeRequest(id, active);
                }
                else {
                    setShowModal(true);
                    setTogglePayload({ id, active });
                }
            };
            const getConsent = (isSaveChanges) => {
                if (isSaveChanges) {
                    handleMakeRequest(togglePayload.id, togglePayload.active);
                }
                setShowModal(false);
            };
            const handleMakeRequest = (id, active) => {
                const payloadData = {
                    payload: {
                        id,
                        is_active: !active,
                    },
                    callback: {
                        onSuccess: (res) => {
                            const { is_active } = res.data.pc_update_flow;
                            setIsActive(is_active);
                        },
                        onError: () => {
                            setIsActive(active);
                        },
                    },
                };
                handleStatusChange(payloadData);
            };
            const latestPublishedFlowConfig = getLatestPublishConfig(row);
            return (_jsxs("div", { className: "flex items-center px-2 text-sm font-normal", children: [_jsx(ToggleButton, { checked: isActive, disabled: row.original.is_default ||
                            !((latestPublishedFlowConfig === null || latestPublishedFlowConfig === void 0 ? void 0 : latestPublishedFlowConfig.status) === STATUS.PUBLISHED), onClick: () => row.original.id &&
                            handleUpdateStatus(row.original.id, isActive), name: "status", focusVisible: false }), _jsx("div", { className: "ml-2", children: _jsx(FormattedMessage, Object.assign({}, (isActive ? messages.active : messages.inactive))) }), showModal && (_jsx(DisableFlowModal, { showModal: showModal, getConsent: getConsent }))] }, row.original.id));
        },
    },
    {
        header: () => _jsx(FormattedMessage, Object.assign({}, messages.version)),
        size: 142,
        enableSorting: false,
        minSize: 142,
        maxSize: 142,
        accessorKey: 'version',
        cell: ({ row }) => {
            const latestPublishedFlowConfig = getLatestPublishConfig(row);
            return (_jsx("div", { className: "px-2 text-sm font-normal", children: latestPublishedFlowConfig
                    ? latestPublishedFlowConfig.version
                    : row.original.flow_configs[0].version }));
        },
    },
    {
        header: () => _jsx(FormattedMessage, Object.assign({}, messages.last_updated)),
        enableSorting: true,
        enableHiding: false,
        sortUndefined: false,
        accessorKey: 'updated_at',
        size: 287,
        minSize: 287,
        maxSize: 287,
        sortingFn: updatedAtSortingFn,
        cell: ({ row }) => {
            const updatedAt = get(row, 'original.updated_at');
            const formattedDate = updatedAt
                ? formatDate(new Date(updatedAt), 'withTime')
                : '';
            return (_jsx("div", { className: "px-2", children: _jsx(User, { showEmail: true, data: row.original.user_by_updated_by, additionalContent: formattedDate }) }));
        },
    },
    {
        header: '',
        id: 'delete',
        enableSorting: false,
        size: 73,
        minSize: 73,
        maxSize: 73,
        accessorFn: () => { },
        cell: ({ row }) => (_jsx("div", { className: "px-2", children: row.original.flow_configs[0].status === STATUS.DRAFT ? (_jsx(TrashIcon, { className: "w-5 h-5 text-[#B91C1C] cursor-pointer", onClick: () => handleDeleteClick(row.original.id), "data-testid": "delete-flow-icon" })) : (_jsx(_Fragment, {})) })),
    },
];
