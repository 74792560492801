import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import _ from 'lodash';
const YearView = ({ startingDate, onYearSelect, selectedYear: styear, }) => {
    // Determine the initial year range and selected year
    const startYear = startingDate
        ? startingDate.getFullYear()
        : new Date().getFullYear();
    const years = _.range(startYear - 5, startYear + 7);
    const handleYearClick = (year) => {
        if (onYearSelect) {
            onYearSelect(year);
        }
    };
    const handleYearKeyDown = (event, year) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            handleYearClick(year);
        }
    };
    return (_jsx("div", { className: "pt-4 flex flex-wrap w-[225px] h-[184px] mb-2", children: years.map((year, index) => {
            return (_jsx("div", { role: "button", tabIndex: 0, onClick: () => handleYearClick(year), onKeyDown: (event) => handleYearKeyDown(event, year), className: classNames('w-[calc(25%)] h-[61px] flex justify-center items-center cursor-pointer rounded-md font-roboto font-normal text-sm leading-[21px] text-gray-900', {
                    'bg-[#1D4ED80D]': styear === year,
                }), children: year }, index));
        }) }));
};
export default YearView;
