import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { FilterButton } from '@components/Flows/Filters/FilterButton';
import Filters from '@components/ProctorNgSessions/Filters';
import { SESSION_TABS } from '@components/ProctorNgSessions/types';
import { USER_ROLE } from '@utils/data/enums';
const SessionFilters = (props) => {
    const { updateAppliedFilters, userRole, selectedTab, proctors } = props;
    const [showFilter, setShowFilter] = useState(false);
    const toogleFilterView = () => {
        setShowFilter((prev) => !prev);
    };
    const isShowTenant = (userRole === USER_ROLE.PROCTOR_ADMIN &&
        selectedTab === SESSION_TABS.ALL_SESSIONS) ||
        (userRole === USER_ROLE.PROCTOR &&
            selectedTab === SESSION_TABS.REVIEW_PENDING);
    const isShowSessionStatus = (userRole === USER_ROLE.PROCTOR_ADMIN &&
        selectedTab === SESSION_TABS.ALL_SESSIONS) ||
        (userRole === USER_ROLE.PROCTOR &&
            selectedTab === SESSION_TABS.REVIEW_PENDING);
    const isShowReviewStatus = userRole === USER_ROLE.PROCTOR_ADMIN &&
        selectedTab === SESSION_TABS.ALL_SESSIONS;
    const isShowCompletedOnDate = (userRole === USER_ROLE.PROCTOR_ADMIN &&
        selectedTab === SESSION_TABS.ALL_SESSIONS) ||
        (userRole === USER_ROLE.PROCTOR &&
            selectedTab === SESSION_TABS.REVIEW_PENDING);
    const isShowRequestedOnDate = (userRole === USER_ROLE.PROCTOR_ADMIN &&
        selectedTab === SESSION_TABS.REVIEW_PENDING) ||
        (userRole === USER_ROLE.PROCTOR && selectedTab === SESSION_TABS.REVIEWED);
    const showProctor = userRole === USER_ROLE.PROCTOR_ADMIN &&
        selectedTab === SESSION_TABS.PENDING;
    return (_jsxs(_Fragment, { children: [_jsx(FilterButton, { hasAppliedFilters: false, onClick: toogleFilterView }), _jsx(Filters, { updateAppliedFilters: updateAppliedFilters, showFilter: showFilter, toogleFilterView: toogleFilterView, isShowTenant: isShowTenant, isShowSessionStatus: isShowSessionStatus, isShowReviewStatus: isShowReviewStatus, isShowCompletedOnDate: isShowCompletedOnDate, isShowRequestedOnDate: isShowRequestedOnDate, showProctor: showProctor, proctors: proctors })] }));
};
export default SessionFilters;
