import { defineMessages } from 'react-intl';
export const scope = 'app.components.shared.datepicker.date_input_box';
export default defineMessages({
    invalid_date: {
        id: `${scope}.invalid_date`,
        defaultMessage: 'Enter valid date',
    },
    calendar_icon: {
        id: `${scope}.calendar_icon`,
        defaultMessage: 'Calendar Icon',
    },
});
