import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { shortMonthNames } from '@components/Shared/DatePicker/enums';
const MonthsView = ({ onMonthSelect, selectedMonth, }) => {
    const handleMonthClick = (monthIndex) => {
        onMonthSelect(monthIndex);
    };
    const handleKeyDown = (event, monthIndex) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            onMonthSelect(monthIndex);
        }
    };
    return (_jsx("div", { className: "pt-4 flex flex-wrap w-[225px] h-[184px] mb-2", children: shortMonthNames.map((month, index) => {
            const isSelectedMonth = index === selectedMonth;
            return (_jsx("div", { role: "button", tabIndex: 0, className: classNames('w-[calc(25%)] h-[61px] flex justify-center items-center cursor-pointer rounded-md font-roboto font-normal text-sm leading-[21px] text-gray-900', { 'bg-[#1D4ED80D]': isSelectedMonth }), onClick: () => handleMonthClick(index), onKeyDown: (event) => handleKeyDown(event, index), style: { color: '#111827' }, children: month }, month));
        }) }));
};
export default MonthsView;
