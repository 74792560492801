import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '@components/Base/Button';
import { Dropdown } from '@components/Base/Dropdown';
import Modal from '@components/Base/Modal';
import { Textarea } from '@components/Base/Textarea';
import DatePicker from '@components/Shared/DatePicker';
import messages from './messages';
const AssignProvider = ({ openAddFlowModal, handleCloseModal, data, onRequestReview, selectedCount, hasInProgressSession, headerText, isEdit, }) => {
    const [selectedProvider, setSelectedProvider] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [requestReason, setRequestReason] = useState('');
    const intl = useIntl();
    const onDateSelect = (date) => {
        setSelectedDate(date);
    };
    const requestReview = () => {
        if (selectedProvider && selectedDate) {
            onRequestReview({
                provider: selectedProvider.value,
                dueDate: selectedDate,
                requestReason: requestReason,
            });
            handleCloseModal();
        }
    };
    return (_jsxs(Modal, { size: "sm", show: openAddFlowModal, onCancel: handleCloseModal, closeOnOverlayClick: false, children: [headerText && !selectedCount && (_jsx("p", { className: "font-semibold", children: headerText })), selectedCount && (_jsx("p", { className: "font-semibold", children: `${selectedCount} sessions selected` })), _jsxs("p", { className: "pb-1.5 pt-3 text-sm font-normal", children: [' ', _jsx(FormattedMessage, Object.assign({}, messages.assign_provider))] }), _jsxs("div", { className: "w-full", children: [_jsx(Dropdown, { id: "provider", isMulti: false, value: selectedProvider, isSearchable: true, placeholder: "Select provider...", onChange: (provider) => {
                            setSelectedProvider(provider);
                        }, options: data }), _jsx("div", { className: "pt-4", children: _jsx(Textarea, { maxLength: 100, value: requestReason, onChange: (e) => setRequestReason(e.target.value), placeholder: intl.formatMessage(messages.reason_text_box_placeholder), label: intl.formatMessage(messages.reason_text_box_header), name: "reasonDescription", className: "resize-none" }) }), _jsx("p", { className: "pt-4 pb-1.5 text-sm font-normal", children: _jsx(FormattedMessage, Object.assign({}, messages.due_date)) }), _jsx(DatePicker, { onChange: onDateSelect })] }), hasInProgressSession && (_jsxs("div", { className: "h-14 bg-yellow-50 w-full mt-4 rounded flex items-center", children: [_jsx(FontAwesomeIcon, { icon: faTriangleExclamation, className: "text-amber-400 pl-4", size: "2xl", tabIndex: 0 }), _jsxs("span", { className: "font-normal text-sm pl-4", children: [_jsx(FormattedMessage, Object.assign({}, messages.sessions)), ' ', _jsx("span", { className: "font-medium", children: _jsx(FormattedMessage, Object.assign({}, messages.in_progress)) }), _jsx(FormattedMessage, Object.assign({}, messages.will_not_be_assigned))] })] })), _jsxs("div", { className: "flex justify-end pt-6", children: [_jsx(Button, { className: "ml-2 text-sm", onClick: handleCloseModal, variant: "outline", children: _jsx(FormattedMessage, Object.assign({}, messages.go_back)) }), _jsx(Button, { className: "ml-2 text-sm", "data-testid": "request-review-button", onClick: requestReview, variant: "base", disabled: !(selectedProvider && selectedDate), children: isEdit ? (_jsx(FormattedMessage, Object.assign({}, messages.save_changes))) : (_jsx(FormattedMessage, Object.assign({}, messages.request_review))) })] })] }));
};
export default AssignProvider;
