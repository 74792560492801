import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import SessionList from '@components/ProctorNgSessions/SessionList';
import { sessionTableData } from '@components/ProctorNgSessions/SessionList/data';
import { getProctorsAction } from '@containers/sessions/proctor_ng/slice';
import { useAppSelector, useAppDispatch } from '@utils/hooks';
const ProctorNgSessions = (props) => {
    const proctorData = useAppSelector((state) => state.sessionsNg.proctors);
    const { data, isSuccess: hasProctorListLoaded } = proctorData;
    const [searchString, setSearchString] = useState('');
    const { role, handleCancelProctor } = props;
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (!hasProctorListLoaded) {
            dispatch(getProctorsAction());
        }
    }, []);
    return (_jsx(SessionList, { sessionData: sessionTableData, userRole: role, searchString: searchString, updateSearchString: setSearchString, handleCancelProctor: handleCancelProctor, proctors: data }));
};
export default ProctorNgSessions;
