import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import AssignProvider from '@components/AssignProvider';
import { Button } from '@components/Base/Button';
import EmptyMessagePage from '@components/Base/EmptyMessagePage';
import SearchInput from '@components/Base/Input';
import Tabs from '@components/Base/Tabs';
import CancelProvider from '@components/CancelProvider';
import { SESSION_TABS } from '@components/ProctorNgSessions/types';
import { providerList } from '@containers/sessions/types';
import { USER_ROLE } from '@utils/data/enums';
import ClipBoardIcon from 'app/images/clipboard.svg';
import messages from './messages';
import RequestsList from '../RequestsList';
import SessionFilters from '../SessionFilters';
import SessionListTable from '../SessionsTable';
import { createColumns, getCustomColumns } from '../SessionsTable/columns';
const SessionList = ({ sessionData, role, examList, selectedTab, handleSelectedTab, updateAppliedFilters, }) => {
    const [selectedCount, setSelectedCount] = useState(0);
    const [resetSelection, setResetSelection] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [hasInProgressSession] = useState(false);
    const [openCancelProvider, setOpenCancelProvider] = useState(false);
    const handleTabSelect = (value) => {
        handleSelectedTab(value);
        setResetSelection(true);
    };
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
    const handleRequestReview = (_reviewRequest) => {
        // Need to handle the request review funtionality
    };
    const toggleCancelProviderModal = () => {
        setOpenCancelProvider(!openCancelProvider);
    };
    const applyFilters = (filters) => {
        updateAppliedFilters(filters);
    };
    const columns = useMemo(() => {
        const customColumns = getCustomColumns[USER_ROLE.COORDINATOR][selectedTab];
        return customColumns
            .map((key) => {
            const filteredColumns = createColumns({
                toggleCancelProviderModal,
            }).filter((column) => column.id === key);
            return filteredColumns.length > 0 ? filteredColumns[0] : null;
        })
            .filter((column) => column !== null);
    }, [selectedTab]);
    const handleTableCheck = (rowSelection) => {
        const selectionCount = rowSelection !== null ? Object.keys(rowSelection).length : 0;
        setSelectedCount(selectionCount);
    };
    return (_jsx("div", { className: "flex w-full px-8 py-5", children: _jsxs("div", { className: "flex flex-col w-full", children: [_jsxs("div", { className: "flex justify-between items-center", children: [_jsxs("div", { className: "flex items-center", children: [_jsx(Tabs, { tabData: [
                                        {
                                            label: _jsx(FormattedMessage, Object.assign({}, messages.all_sessions)),
                                            value: 'sessions',
                                            isShow: true,
                                        },
                                        {
                                            label: _jsx(FormattedMessage, Object.assign({}, messages.requests)),
                                            value: 'requests',
                                            isShow: true,
                                        },
                                    ], selectedValue: selectedTab, onSelect: handleTabSelect }), selectedCount > 0 && (_jsx(Button, { className: "ml-2", variant: "base", "data-testid": "request-review-btn", onClick: () => {
                                        setIsModalOpen(true);
                                    }, children: _jsx(FormattedMessage, Object.assign({}, (selectedTab === SESSION_TABS.ALL_SESSIONS
                                        ? messages.request_review
                                        : messages.edit_request))) }))] }), _jsxs("div", { className: "flex items-center gap-4", children: [_jsx(SearchInput, { initialValue: '', handleChange: () => { }, placeholderMessageId: "app.components.proctorng.sessions.sessions_list.search_sessions" }), _jsx(SessionFilters, { userRole: role, selectedTab: selectedTab, updateAppliedFilters: applyFilters, examList: examList })] })] }), sessionData.length > 0 ? (_jsx(_Fragment, { children: selectedTab === 'sessions' ? (_jsx(SessionListTable, { data: sessionData, columns: columns, handleTableCheck: handleTableCheck, resetSelection: resetSelection })) : (_jsx(RequestsList, { data: sessionData, columns: columns, handleTableCheck: handleTableCheck, resetSelection: resetSelection })) })) : (_jsx(EmptyMessagePage, { icon: ClipBoardIcon, titleMessage: SESSION_TABS.ALL_SESSIONS === selectedTab
                        ? messages.no_session
                        : messages.no_request_pending, descriptionMessage: SESSION_TABS.ALL_SESSIONS === selectedTab
                        ? messages.no_session_description
                        : messages.no_request_pending_description, descriptionWidth: "w-48" })), _jsx(AssignProvider, { data: providerList, openAddFlowModal: isModalOpen, handleCloseModal: handleCloseModal, onRequestReview: handleRequestReview, selectedCount: selectedCount, isEdit: !(selectedTab === SESSION_TABS.ALL_SESSIONS), hasInProgressSession: hasInProgressSession }), _jsx(CancelProvider, { openCancelProvider: openCancelProvider, toggleCancelProviderModal: toggleCancelProviderModal, handleCancelProvider: () => { } })] }) }));
};
export default SessionList;
