export const convertToTitleCase = (snakeCaseStr) => {
    // 1. Split the string into words
    const words = snakeCaseStr.split('_');
    // 2. Capitalize the first letter of each word
    const capitalizedWords = words.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
    });
    // 3. Join the words together with a space
    const titleCaseStr = capitalizedWords.join(' ');
    return titleCaseStr;
};
