import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { RATING } from '@utils/data/enums';
import High from 'app/images/svgs/high-signal.svg';
import Low from 'app/images/svgs/low-signal.svg';
import Medium from 'app/images/svgs/medium-signal.svg';
import messages from './messages';
const RatingAtom = ({ rating }) => {
    const integrity_rating = useMemo(() => {
        switch (rating) {
            case RATING.LOW:
                return (_jsxs(_Fragment, { children: [_jsx("img", { src: Low, alt: "Low", className: "mt-0.5 h-4" }), _jsx("div", { className: "text-sm font-medium font-roboto text-red-600", children: _jsx(FormattedMessage, Object.assign({}, messages.low)) })] }));
            case RATING.MEDIUM:
                return (_jsxs(_Fragment, { children: [_jsx("img", { src: Medium, alt: "Medium", className: "mt-0.5 h-4" }), _jsx("div", { className: "text-sm font-medium font-roboto text-amber-400", children: _jsx(FormattedMessage, Object.assign({}, messages.medium)) })] }));
            case RATING.HIGH:
                return (_jsxs(_Fragment, { children: [_jsx("img", { src: High, alt: "High", className: "mt-0.5 h-4" }), _jsx("div", { className: "text-sm font-medium font-roboto text-green-600", children: _jsx(FormattedMessage, Object.assign({}, messages.high)) })] }));
            default:
                return _jsx(_Fragment, { children: "-" });
        }
    }, [rating]);
    return _jsx("div", { className: "flex gap-1", children: integrity_rating });
};
export default RatingAtom;
