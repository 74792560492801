import { all, fork } from 'redux-saga/effects';
import { appSagas } from '@containers/app/saga';
import flowDetailSaga from '@containers/flowDetails/saga';
import { flowListSagas } from '@containers/flowList/saga';
import { projectsSaga } from '@containers/projects/saga';
import { proctorsNgSessionsSaga } from '@containers/sessions/proctor_ng/saga';
import { consoleSessionsSaga } from '@containers/sessions/proview_console/saga';
import { userListSaga } from '@containers/user/saga';
const rootSaga = function* () {
    yield all([
        fork(appSagas),
        fork(projectsSaga),
        fork(flowListSagas),
        fork(flowDetailSaga),
        fork(userListSaga),
        fork(proctorsNgSessionsSaga),
        fork(consoleSessionsSaga),
    ]);
};
export default rootSaga;
