import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import AssignProctor from '@components/AssignProctor';
import { Button } from '@components/Base/Button';
import EmptyMessagePage from '@components/Base/EmptyMessagePage';
import SearchInput from '@components/Base/Input';
import CancelProctor from '@components/CancelProctor';
import SessionListTable from '@components/ProctorNgSessions/SessionTable';
import { createColumns, } from '@components/ProctorNgSessions/SessionTable/Columns';
import SessionTabs from '@components/ProctorNgSessions/SessionTabs';
import { SESSION_TABS, getCustomColumns, } from '@components/ProctorNgSessions/types';
import { USER_ROLE } from '@utils/data/enums';
import ClipBoardIcon from 'app/images/clipboard.svg';
import messages from './messages';
import SessionFilters from '../SessionFilters';
const SessionList = ({ sessionData, userRole, searchString, updateSearchString, handleCancelProctor, proctors, }) => {
    const [selectedTab, setSelectedTab] = useState(userRole === USER_ROLE.PROCTOR_ADMIN
        ? SESSION_TABS.ALL_SESSIONS
        : SESSION_TABS.PENDING);
    const [selectedCount, setSelectedCount] = useState(0);
    const [resetSelection, setResetSelection] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [hasInProgressSession] = useState(false);
    const [openCancelProctor, setOpenCancelProctor] = useState(false);
    const data = useMemo(() => {
        return sessionData.filter((session) => {
            if (selectedTab === SESSION_TABS.PENDING) {
                return session.review === SESSION_TABS.PENDING;
            }
            if (selectedTab === SESSION_TABS.REVIEWED) {
                return session.review === SESSION_TABS.REVIEWED;
            }
            return session;
        });
    }, [selectedTab]);
    const handleRequestReview = (_details) => { };
    const toggleCancelProctorModal = () => {
        setOpenCancelProctor(!openCancelProctor);
    };
    const columns = useMemo(() => {
        const customColumns = getCustomColumns[userRole][selectedTab];
        return customColumns
            .map((key) => {
            const filteredColumns = createColumns({
                toggleCancelProctorModal,
                proctors,
            }).filter((column) => column.id === key);
            return filteredColumns.length > 0 ? filteredColumns[0] : null;
        })
            .filter((column) => column !== null);
    }, [userRole, selectedTab]);
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
    const handleTabSelect = (value) => {
        setSelectedTab(value);
        setResetSelection(true);
    };
    const toogleRequestModal = () => {
        setIsModalOpen(!isModalOpen);
    };
    const handleTableCheck = (rowSelection) => {
        const selectionCount = rowSelection !== null ? Object.keys(rowSelection).length : 0;
        setSelectedCount(selectionCount);
        setResetSelection(false);
    };
    const isMultiselect = userRole === USER_ROLE.PROCTOR_ADMIN;
    return (_jsxs("div", { className: "flex w-full px-8 py-5", children: [_jsxs("div", { className: "flex flex-col w-full", children: [_jsxs("div", { className: "flex justify-between items-center", children: [_jsxs("div", { className: "flex items-center gap-4", children: [_jsx(SessionTabs, { selectedTab: selectedTab, handleTabSelect: handleTabSelect, userRole: userRole }), selectedCount > 0 && (_jsx(Button, { variant: "base", onClick: toogleRequestModal, "data-testid": "request-review", children: _jsx(FormattedMessage, Object.assign({}, (selectedTab === 'sessions'
                                            ? messages.request_review
                                            : messages.edit_request))) }))] }), _jsxs("div", { className: "flex items-center gap-4", children: [_jsx(SearchInput, { initialValue: searchString, handleChange: updateSearchString, placeholderMessageId: "app.components.proctorng.sessions.sessions_list.search_sessions" }), _jsx(SessionFilters, { proctors: proctors, userRole: userRole, selectedTab: selectedTab, updateAppliedFilters: () => { } })] })] }), sessionData.length > 0 ? (_jsx(SessionListTable, { data: data, columns: columns, isMultiselect: isMultiselect, handleTableCheck: handleTableCheck, resetSelection: resetSelection })) : (_jsx(EmptyMessagePage, { icon: ClipBoardIcon, titleMessage: SESSION_TABS.ALL_SESSIONS === selectedTab
                            ? messages.no_session
                            : messages.no_review_pending, descriptionMessage: SESSION_TABS.ALL_SESSIONS === selectedTab
                            ? messages.no_session_description
                            : messages.no_review_pending_description, descriptionWidth: "w-48" }))] }), _jsx(AssignProctor, { data: proctors, openAddFlowModal: isModalOpen, handleCloseModal: handleCloseModal, onRequestReview: handleRequestReview, selectedCount: selectedCount, isEdit: !(selectedTab === SESSION_TABS.ALL_SESSIONS), hasInProgressSession: hasInProgressSession }), _jsx(CancelProctor, { openCancelProctor: openCancelProctor, toggleCancelProctorModal: toggleCancelProctorModal, handleCancelProctor: handleCancelProctor })] }));
};
export default SessionList;
