import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import ReactDOM from 'react-dom';
const TextDescription = ({ text, maxNumberOfLines, textClass, tooltipClass, showToolTip, pointerColor, }) => {
    const [tooltipPosition, setTooltipPosition] = useState(null);
    const handleMouseEnter = (e) => {
        const { top, left } = e.currentTarget.getBoundingClientRect();
        // Position tooltip above the text
        setTooltipPosition({ top: top - 55 + window.scrollY, left: left });
    };
    const handleMouseLeave = () => {
        setTooltipPosition(null);
    };
    return (_jsxs("div", { className: "relative w-full h-full group", children: [_jsx("div", { className: `overflow-hidden text-sm font-normal ${textClass}`, style: {
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: maxNumberOfLines,
                }, onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave, children: text }), showToolTip &&
                tooltipPosition &&
                ReactDOM.createPortal(_jsxs("div", { "data-testid": "tooltip", className: `absolute z-[9999] w-[279px] whitespace-normal text-white text-xs rounded-md p-2 bg-[#0F172AE5] ${tooltipClass}`, style: {
                        top: tooltipPosition.top,
                        left: tooltipPosition.left,
                    }, children: [text, _jsx("div", { className: `absolute top-full left-2.5 w-0 h-0 border-l-[8px] border-l-transparent border-r-[7px] border-r-transparent border-t-[6px] ${tooltipClass ? `border-t-${pointerColor}` : 'border-t-gray-900'}` })] }), document.body)] }));
};
export default TextDescription;
