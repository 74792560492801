import { jsx as _jsx } from "react/jsx-runtime";
import { FormattedMessage } from 'react-intl';
import Tabs from '@components/Base/Tabs';
import { SESSION_TABS } from '@components/ProctorNgSessions/types';
import { USER_ROLE } from '@utils/data/enums';
import messages from './messages';
const SessionTabs = (props) => {
    const { selectedTab, handleTabSelect, userRole } = props;
    return (_jsx(Tabs, { tabData: [
            {
                label: _jsx(FormattedMessage, Object.assign({}, messages.all_sessions)),
                value: SESSION_TABS.ALL_SESSIONS,
                isShow: userRole === USER_ROLE.PROCTOR_ADMIN,
            },
            {
                label: _jsx(FormattedMessage, Object.assign({}, messages.review_pending)),
                value: SESSION_TABS.PENDING,
                isShow: userRole === USER_ROLE.PROCTOR ||
                    userRole === USER_ROLE.PROCTOR_ADMIN,
            },
            {
                label: _jsx(FormattedMessage, Object.assign({}, messages.reviewed)),
                value: SESSION_TABS.REVIEWED,
                isShow: userRole === USER_ROLE.PROCTOR,
            },
        ], selectedValue: selectedTab, onSelect: handleTabSelect }));
};
export default SessionTabs;
