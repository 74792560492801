export var SESSION_TABS;
(function (SESSION_TABS) {
    SESSION_TABS["ALL_SESSIONS"] = "sessions";
    SESSION_TABS["REVIEWED"] = "reviewed";
    SESSION_TABS["PENDING"] = "pending";
    SESSION_TABS["REVIEW_PENDING"] = "review_pending";
})(SESSION_TABS || (SESSION_TABS = {}));
export const getCustomColumns = {
    PROCTOR: {
        reviewed: [
            'reviews',
            'session_candidate_proctor',
            'exam_details',
            'reason_for_request',
            'session_rating',
            'accomodations',
            'requested_on',
            'external_id',
            'proctoring_type',
        ],
        pending: [
            'reviews',
            'session_candidate_proctor',
            'due_date',
            'exam_details',
            'reason_for_request',
            'session_rating',
            'session_duration',
            'proctoring_type',
            'accomodations',
            'tenant',
            'status',
            'summary',
            'attempt',
            'external_id',
            'completed_on',
            'menu',
        ],
    },
    PROCTOR_ADMIN: {
        pending: [
            'admin_reviews',
            'session_candidate',
            'exam_details',
            'reason_for_request',
            'session_rating',
            'proctor',
            'accomodations',
            'aging',
            'requested_on',
            'external_id',
            'proctoring_type',
            'edit',
            'delete',
        ],
        sessions: [
            'admin_reviews',
            'session_candidate',
            'due_date',
            'exam_details',
            'reason_for_request',
            'session_rating',
            'session_duration',
            'proctoring_type',
            'accomodations',
            'tenant',
            'status',
            'reason',
            'summary',
            'attempt',
            'external_id',
            'completed_on',
            'menu',
        ],
    },
};
