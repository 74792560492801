import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import Header from '@components/Base/Header';
import { ROLE } from '@containers/app/types';
import messages from '@containers/sessions/messages';
import { HeaderContext } from '@contexts/HeaderContext';
import { useAppSelector } from '@utils/hooks';
import GroupDropdown from 'app/components/Base/GroupDropdown';
import ProctorNgSessions from './proctor_ng';
import ProviewConsoleSessions from './proview_console';
const Sessions = () => {
    const navigate = useNavigate();
    const { currentProject } = useAppSelector((state) => state.projects);
    const { user } = useAppSelector((state) => state.app);
    const { handleProjectChange, options } = useContext(HeaderContext);
    const handleCancelProctor = () => { };
    const renderContentByRole = () => {
        switch (user.currentRole) {
            case ROLE.TENANT_ADMIN:
            case ROLE.COORDINATOR:
                return _jsx(ProviewConsoleSessions, { role: user.currentRole });
            case ROLE.PROCTOR:
            case ROLE.PROCTOR_ADMIN:
                return (_jsx(ProctorNgSessions, { role: user.currentRole, handleCancelProctor: handleCancelProctor }));
            default:
                return null;
        }
    };
    const isCoordinator = user.currentRole === ROLE.COORDINATOR ||
        user.currentRole === ROLE.TENANT_ADMIN;
    useEffect(() => {
        if (currentProject.token && isCoordinator) {
            navigate(`/sessions?project_uuid=${currentProject.token}`);
        }
    }, [currentProject.token, isCoordinator]);
    return (_jsxs(_Fragment, { children: [_jsx(Header, { details: { label: _jsx(FormattedMessage, Object.assign({}, messages.sessions)) }, children: isCoordinator ? (_jsx("div", { className: "w-[308px]", children: _jsx(GroupDropdown, { selectedValue: { label: currentProject.name }, options: options, onChange: handleProjectChange }) })) : (_jsx(_Fragment, {})) }), renderContentByRole()] }));
};
export default Sessions;
