var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { isEmpty } from 'lodash';
import mixpanel from 'mixpanel-browser';
import { Outlet, useLocation, useNavigate, useParams, useSearchParams, } from 'react-router-dom';
import { setMixpanelInitialized } from '@containers/app/slice';
import { ROLE } from '@containers/app/types';
import { getUser } from '@containers/auth/login/api';
import { loginRedirect } from '@containers/auth/login/helpers';
import { getProjectsAction, setCurrentProject, } from '@containers/projects/slice';
import { getMixpanelProperties } from '@utils/helpers';
import { useAppDispatch, useAppSelector } from '@utils/hooks';
import { userLoggedInEvent } from '@utils/mixpanelActions';
const Login = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const accessToken = queryParams.get('accessToken');
    const { data: projects } = useAppSelector((state) => state.projects);
    const { user: userData } = useAppSelector((state) => state.app);
    const [enableMixPanel, setEnableMixPanel] = useState(false);
    const user = getUser();
    const checkLogin = () => __awaiter(void 0, void 0, void 0, function* () {
        let path = '';
        if (!accessToken && user && !user.isAnonymous) {
            if (userData.currentRole === ROLE.PROCTOR_ADMIN ||
                userData.currentRole === ROLE.PROCTOR) {
                path = '/home';
            }
            if (userData.currentRole === ROLE.COORDINATOR ||
                userData.currentRole === ROLE.TENANT_ADMIN) {
                if (isEmpty(params) && !searchParams.get('project_uuid')) {
                    path = projects[0] ? `/projects/${projects[0].token}` : '/projects';
                    if (projects[0]) {
                        dispatch(setCurrentProject(projects[0]));
                    }
                }
                else {
                    path = `${location.pathname}${location.search}`;
                }
            }
            setEnableMixPanel(true);
            navigate(path, { replace: true });
        }
        else if (!accessToken) {
            loginRedirect({ location });
        }
    });
    useEffect(() => {
        if (enableMixPanel && user && !user.isAnonymous) {
            const mixpanelToken = process.env.MIXPANEL_PROJECT_TOKEN;
            try {
                mixpanel.init(mixpanelToken, {
                    ignore_dnt: true,
                    debug: true,
                    opt_out_tracking_by_default: !enableMixPanel,
                });
                mixpanel.identify(user.uid);
                if (userData.tenant) {
                    dispatch(setMixpanelInitialized());
                    const mixpanelProperties = getMixpanelProperties(userData);
                    userLoggedInEvent(mixpanelProperties);
                }
            }
            catch (error) {
                Sentry.captureException(error);
            }
        }
    }, [enableMixPanel, user, userData.tenant]);
    useEffect(() => {
        checkLogin();
    }, [projects]);
    useEffect(() => {
        if (user && !user.isAnonymous && userData.currentRole) {
            (userData.currentRole === ROLE.COORDINATOR ||
                userData.currentRole === ROLE.TENANT_ADMIN) &&
                dispatch(getProjectsAction());
        }
    }, [user, dispatch, userData]);
    return _jsx(Outlet, {});
};
export default Login;
