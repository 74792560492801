import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, Fragment, useState, useEffect, } from 'react';
import { Dialog, DialogTitle, Transition, TransitionChild, } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
const SidePanel = ({ children, title, show = false, onClose, allowCustomHeader = false, disabledAutoClose = false, initialFocus, containerPadding = 'p-6', }) => {
    const [open, setOpen] = useState(show);
    useEffect(() => {
        setOpen(show);
    }, [show]);
    const handleOnClose = () => {
        if (disabledAutoClose)
            return;
        onClose ? onClose() : setOpen(false);
    };
    return (_jsx(Transition, { show: open, as: Fragment, children: _jsx(Dialog, { as: "div", className: "fixed inset-0 z-50", "data-testid": "side-panel", onClose: handleOnClose, initialFocus: initialFocus, children: _jsx("div", { className: "absolute inset-0", children: _jsx("div", { "data-testid": "action-panel", className: "fixed bottom-0 right-0 flex max-w-full pointer-events-none h-[calc(100vh-5rem)]", children: _jsx(TransitionChild, { as: Fragment, enter: "transform transition ease-in-out duration-500 sm:duration-700", enterFrom: "translate-x-full", enterTo: "translate-x-0", leave: "transform transition ease-in-out duration-500 sm:duration-700", leaveFrom: "translate-x-0", leaveTo: "translate-x-full", children: _jsx("div", { className: "pointer-events-auto", children: _jsx("div", { className: classNames('flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl'), children: _jsxs("div", { className: "flex flex-col flex-1 min-h-0", children: [!allowCustomHeader && (_jsx("div", { className: classNames('flex items-start justify-between top-0 pt-3 sticky w-full pr-10 mb-3 shadow-xs border-b-2 border-gray-100'), children: _jsxs("div", { className: "flex items-center justify-between w-full mb-2 ml-3 align-middle h-7", children: [title && (_jsx(DialogTitle, { className: "text-base font-normal text-gray-900 lg:text-lg lg:font-medium", children: title })), _jsxs("button", { "data-testid": "button-close", type: "button", className: "text-gray-400 bg-white rounded-md hover:text-gray-500", onClick: handleOnClose, children: [_jsx("span", { className: "sr-only", children: "Close panel" }), _jsx(XMarkIcon, { className: "w-6 h-6", "aria-hidden": "true" })] })] }) })), _jsx("div", { className: classNames('flex flex-1 flex-col overflow-y-auto', containerPadding), children: children })] }) }) }) }) }) }) }) }));
};
export default memo(SidePanel);
