import { createSlice } from '@reduxjs/toolkit';
export const initialState = {
    proctors: {
        isLoading: false,
        isSuccess: false,
        isError: false,
        error: null,
        data: [],
    },
};
export const sessionsSlice = createSlice({
    name: 'sessionsNg',
    initialState: initialState,
    reducers: {
        getProctorsAction: (state) => {
            state.proctors.isLoading = true;
            state.proctors.isSuccess = false;
            state.proctors.isError = false;
            state.proctors.error = null;
        },
        getProctorsSuccessAction: (state, { payload }) => {
            state.proctors.data = payload;
            state.proctors.isLoading = false;
            state.proctors.isError = false;
            state.proctors.isSuccess = true;
        },
        getProctorsErrorAction: (state, { payload: error }) => {
            state.proctors.isLoading = false;
            state.proctors.isSuccess = false;
            state.proctors.isError = true;
            state.proctors.error = error;
        },
    },
});
export const { getProctorsAction, getProctorsSuccessAction, getProctorsErrorAction, } = sessionsSlice.actions;
export default sessionsSlice.reducer;
