import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import Edited from 'app/images/svgs/edited.svg';
import Flags from './Flags';
import RatingAtom from './RatingAtom';
import StatusHistory from './StatusHistory';
const Rating = ({ rating, statusHistory, flags }) => {
    const [showStatusHistory, setShowStatusHistory] = useState(false);
    const toggleStatusHistory = () => {
        setShowStatusHistory(!showStatusHistory);
    };
    return (_jsxs("div", { className: "flex gap-2 relative pl-1 justify-center group cursor-default h-[20px]", children: [_jsx(RatingAtom, { rating: rating }), rating && flags ? (_jsx(Flags, { critical: flags.critical, high: flags.high, medium: flags.medium, low: flags.low })) : null, statusHistory && statusHistory.length ? (_jsxs(_Fragment, { children: [_jsx("button", { onClick: toggleStatusHistory, onMouseLeave: showStatusHistory ? toggleStatusHistory : () => { }, children: _jsx("img", { src: Edited, alt: "Edited", className: "h-4" }) }), showStatusHistory && _jsx(StatusHistory, { statusHistory: statusHistory })] })) : (_jsx(_Fragment, {}))] }));
};
export default Rating;
