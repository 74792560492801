// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.attendee-column {
  filter: drop-shadow(8px 0px 16px rgba(17, 24, 39, 0.08));
  clip-path: inset(0 -28px 0px 0px);
}
`, "",{"version":3,"sources":["webpack://./app/components/ProctorNgSessions/styles.css"],"names":[],"mappings":"AAAA;EACE,wDAAwD;EACxD,iCAAiC;AACnC","sourcesContent":[".attendee-column {\n  filter: drop-shadow(8px 0px 16px rgba(17, 24, 39, 0.08));\n  clip-path: inset(0 -28px 0px 0px);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
