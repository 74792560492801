import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import SessionList from '@components/ProviewConsoleSessions/SessionsList';
import { getProSessionsAction } from '@containers/sessions/proview_console/slice';
import { useAppDispatch, useAppSelector } from '@utils/hooks';
const ProviewConsoleSessions = (props) => {
    const { role } = props;
    const { data: consoleSessions } = useAppSelector((state) => state.consoleSessions);
    const dispatch = useAppDispatch();
    const [selectedTab, setSelectedTab] = useState('sessions');
    const [appliedFilters, updateAppliedFilters] = useState({});
    const sessionList = useMemo(() => {
        return consoleSessions.filter((session) => {
            var _a;
            return selectedTab === 'sessions'
                ? session
                : ((_a = session.session_reviews[0]) === null || _a === void 0 ? void 0 : _a.review_status) === 'PENDING';
        });
    }, [consoleSessions, selectedTab]);
    const examList = useMemo(() => {
        return consoleSessions
            .filter((session) => {
            var _a;
            return ((selectedTab === 'sessions'
                ? session
                : ((_a = session.session_reviews[0]) === null || _a === void 0 ? void 0 : _a.review_status) === 'PENDING') &&
                session.title !== null);
        })
            .map((session) => ({
            label: session.title,
            value: session.id,
        }));
    }, [consoleSessions, selectedTab]);
    const handleSelectedTab = (value) => {
        setSelectedTab(value);
    };
    const parseFilterValue = (filter) => filter.map(({ value }) => value.toUpperCase());
    useEffect(() => {
        const { sessionFilterStatus, sessionExams, rating, selectedAccomodations, status, selectedDateRange, selectedRequestedRange, } = appliedFilters;
        const filters = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, ((sessionExams === null || sessionExams === void 0 ? void 0 : sessionExams.length) && {
            title: { _in: sessionExams.map(({ label }) => label) },
        })), ((sessionFilterStatus === null || sessionFilterStatus === void 0 ? void 0 : sessionFilterStatus.length) && {
            session_status: { _in: parseFilterValue(sessionFilterStatus) },
        })), ((rating === null || rating === void 0 ? void 0 : rating.length) && {
            system_rating: { _in: rating.map((value) => value.toLowerCase()) },
        })), ((status === null || status === void 0 ? void 0 : status.length) && {
            session_type: { _in: status.map((value) => value.toUpperCase()) },
        })), ((selectedAccomodations === null || selectedAccomodations === void 0 ? void 0 : selectedAccomodations.length) && {
            accommodation: {
                accommodation_type: {
                    _in: selectedAccomodations.map(({ value }) => value.toUpperCase()),
                },
            },
        })), (selectedDateRange &&
            selectedDateRange.startDate &&
            selectedDateRange.endDate && {
            completed_at: {
                _gte: selectedDateRange.startDate,
                _lte: selectedDateRange.endDate,
            },
        })), (selectedRequestedRange &&
            selectedRequestedRange.startDate &&
            selectedRequestedRange.endDate && {
            session_reviews: {
                assigned_at: {
                    _gte: selectedRequestedRange.startDate,
                    _lte: selectedRequestedRange.endDate,
                },
            },
        }));
        dispatch(getProSessionsAction({ conditions: filters }));
    }, [appliedFilters]);
    useEffect(() => {
        if (!consoleSessions.length) {
            dispatch(getProSessionsAction({
                conditions: {},
            }));
        }
    }, []);
    return (_jsx(SessionList, { updateAppliedFilters: updateAppliedFilters, sessionData: sessionList, role: role, examList: examList, selectedTab: selectedTab, handleSelectedTab: handleSelectedTab }));
};
export default ProviewConsoleSessions;
