import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { faArrowDown, faArrowUp, faSort, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { flexRender, getCoreRowModel, getPaginationRowModel, getSortedRowModel, useReactTable, } from '@tanstack/react-table';
import classNames from 'classnames';
import EmptyRecord from '@components/Base/Table/TableHelpers/EmptyRecord';
import TableLoader from './loader/TableLoader';
import { Checkbox } from '../Checkbox';
import './styles.css';
const Table = ({ data, columns, setPagination, pagination, isLoading, isMultiselect, handleSelection = () => { }, resetSelection, }) => {
    const [rowSelection, setRowSelection] = useState({});
    useEffect(() => {
        handleSelection(rowSelection);
    }, [rowSelection]);
    useEffect(() => {
        if (resetSelection) {
            setRowSelection({});
        }
    }, [resetSelection]);
    const columnsWithSelection = isMultiselect
        ? [
            {
                id: 'selection',
                enableSorting: false,
                header: ({ table }) => (_jsx(Checkbox, { checked: table.getIsAllRowsSelected(),
                    onChange: table.getToggleAllRowsSelectedHandler(),
                    disabled: false,
                    id: 0 })),
                cell: (props) => (_jsx(Checkbox, { checked: props.row.getIsSelected(),
                    onChange: props.row.getToggleSelectedHandler(),
                    disabled: false,
                    id: props.row.id })),
                size: 48,
                className: 'text-center left-0 sticky',
                cellClassName: 'text-center ignoreRowClick left-0 sticky z-[1]',
                enableGlobalFilter: false,
            },
            ...columns.filter((item) => !item.hidden),
        ]
        : columns.filter((item) => !item.hidden);
    const table = useReactTable({
        data,
        columns: columnsWithSelection,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onPaginationChange: setPagination,
        state: {
            pagination,
            rowSelection,
        },
        manualPagination: true,
        enableMultiRowSelection: isMultiselect,
        enableRowSelection: isMultiselect,
        onRowSelectionChange: setRowSelection,
        enableFilters: true,
    });
    const { getAllLeafColumns } = table;
    const getSizeClass = (size) => `w-[${size}px]`;
    return (_jsx("div", { className: "h-[calc(100vh-13rem)] mt-5 overflow-auto max-w-[calc(100vw-9rem)] no-scrollbar", children: _jsxs("table", { className: classNames('w-full table-fixed'), children: [_jsx("thead", { className: "sticky top-0 z-[2]", children: table.getHeaderGroups().map((headerGroup) => (_jsx("tr", { children: headerGroup.headers.map((header) => (_jsx("th", { className: classNames('pl-4 py-2 text-sm font-medium text-left text-primary-text bg-gray-100', getSizeClass(header.column.columnDef.size), header.column.columnDef
                                .className), style: { width: `${header.getSize()}px` }, children: header.column.getCanSort() ? (_jsxs("div", { className: "flex items-center", children: [flexRender(header.column.columnDef.header, header.getContext()), _jsx("button", { type: "button", onClick: header.column.getToggleSortingHandler(), className: header.column.getIsSorted()
                                            ? 'ml-2'
                                            : 'ml-2 text-gray-400', children: header.column.getIsSorted() === 'desc' ? (_jsx(FontAwesomeIcon, { icon: faArrowDown })) : header.column.getIsSorted() === 'asc' ? (_jsx(FontAwesomeIcon, { icon: faArrowUp })) : (_jsx(FontAwesomeIcon, { icon: faSort })) })] })) : (flexRender(header.column.columnDef.header, header.getContext())) }, header.id))) }, headerGroup.id))) }), _jsx("tbody", { children: isLoading ? (_jsx(TableLoader, { columns: getAllLeafColumns() })) : data.length === 0 ? (_jsx("tr", { "data-testid": "empty_record", children: _jsx(EmptyRecord, { colSpan: getAllLeafColumns().length }) })) : (_jsx(_Fragment, { children: table.getRowModel().rows.map((row) => (_jsx("tr", { id: row.id, className: classNames('odd:bg-white even:bg-slate-50'), children: row.getVisibleCells().map((cell) => (_jsx("td", { className: classNames(cell.column.columnDef
                                    .cellClassName, 'p-4 bg-inherit'), children: flexRender(cell.column.columnDef.cell, cell.getContext()) }, cell.id))) }, row.id))) })) })] }) }));
};
export default Table;
