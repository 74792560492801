import includes from 'lodash/includes';
import { Coordinator, TenantAdminAndCoordinator } from './coordinator';
import { ProctorPermission } from './proctor';
import { TenantAdmin, ProctorAdmin } from './tenantAdmin';
// Setting default permissions to true
const defaultPermissions = {
    Home: { canView: true, canCreate: true, canDelete: true, canEdit: true },
    Projects: { canView: true, canCreate: true, canDelete: true, canEdit: true },
    Events: { canView: true, canCreate: true, canDelete: true, canEdit: true },
    Sessions: { canView: true, canCreate: true, canDelete: true, canEdit: true },
    Exams: {
        canView: true,
        canCreate: true,
        canDelete: true,
        canEdit: true,
    },
    Proctors: { canView: true, canCreate: true, canDelete: true, canEdit: true },
    Schedule: { canView: true, canCreate: true, canDelete: true, canEdit: true },
    Rooms: { canView: true, canCreate: true, canDelete: true, canEdit: true },
    Help: { canView: true, canCreate: true, canDelete: true, canEdit: true },
    Notifications: {
        canView: true,
        canCreate: true,
        canDelete: true,
        canEdit: true,
    },
    Profile: { canView: true, canCreate: true, canDelete: true, canEdit: true },
    Flows: { canView: true, canCreate: true, canDelete: true, canEdit: true },
    Payments: {
        canView: true,
        canCreate: true,
        canDelete: true,
        canEdit: true,
    },
    Settings: { canView: true, canCreate: true, canDelete: true, canEdit: true },
};
const roleSpecificPermissions = {
    PROCTOR: ProctorPermission,
    TENANT_ADMIN: TenantAdmin,
    COORDINATOR: Coordinator,
    PROCTOR_ADMIN: ProctorAdmin,
    TENANT_ADMIN_AND_COORDINATOR: TenantAdminAndCoordinator,
};
function getUserMasterPermissions() {
    var _a;
    const storageItem = localStorage.getItem('tv.pc');
    if (!storageItem) {
        return Object.assign({}, defaultPermissions);
    }
    const parsedStorage = JSON.parse(storageItem);
    let roles = (_a = parsedStorage.roles) !== null && _a !== void 0 ? _a : [];
    if (includes(roles, 'COORDINATOR') && includes(roles, 'TENANT_ADMIN')) {
        roles = ['TENANT_ADMIN_AND_COORDINATOR'];
    }
    return evaluateAccessPermissions(roles);
}
function evaluateAccessPermissions(roles) {
    const masterPermissions = Object.assign({}, defaultPermissions);
    roles.forEach((role) => {
        const restrictions = roleSpecificPermissions[role];
        if (restrictions) {
            Object.entries(restrictions).forEach(([key, permission]) => {
                var _a;
                (_a = masterPermissions[key]).canView && (_a.canView = permission.canView);
            });
        }
    });
    return masterPermissions;
}
export { getUserMasterPermissions };
