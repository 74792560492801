export const Coordinator = {
    Home: {
        canView: false,
        canCreate: false,
        canDelete: false,
        canEdit: false,
    },
    Flows: {
        canView: false,
        canCreate: false,
        canDelete: false,
        canEdit: false,
    },
    Proctors: {
        canView: false,
        canCreate: false,
        canDelete: false,
        canEdit: false,
    },
    Schedule: {
        canView: false,
        canCreate: false,
        canDelete: false,
        canEdit: false,
    },
    Settings: {
        canView: false,
        canCreate: false,
        canDelete: false,
        canEdit: false,
    },
    Payments: {
        canView: false,
        canCreate: false,
        canDelete: false,
        canEdit: false,
    },
    Rooms: {
        canView: false,
        canCreate: false,
        canDelete: false,
        canEdit: false,
    },
};
export const TenantAdminAndCoordinator = {
    Home: {
        canView: false,
        canCreate: false,
        canDelete: false,
        canEdit: false,
    },
    Flows: {
        canView: false,
        canCreate: false,
        canDelete: false,
        canEdit: false,
    },
    Proctors: {
        canView: false,
        canCreate: false,
        canDelete: false,
        canEdit: false,
    },
    Schedule: {
        canView: false,
        canCreate: false,
        canDelete: false,
        canEdit: false,
    },
    Rooms: {
        canView: false,
        canCreate: false,
        canDelete: false,
        canEdit: false,
    },
};
