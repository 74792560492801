import { call, takeLatest, all, put } from 'redux-saga/effects';
import { getProctorsAction, getProctorsSuccessAction, getProctorsErrorAction, } from '@containers/sessions/proctor_ng/slice';
import fetchData from '@utils/fetchData';
import { GET_PROCTOR_LIST } from './queries';
export function* getProctorsList() {
    try {
        const response = yield call(fetchData, {
            queryString: GET_PROCTOR_LIST,
            queryKey: 'auth_user',
            queryVariables: {},
            forceRefresh: true,
        });
        if (response) {
            yield put(getProctorsSuccessAction(response));
        }
    }
    catch (error) {
        yield put(getProctorsErrorAction(error));
    }
}
export function* watchGetProctors() {
    yield takeLatest(getProctorsAction.type, getProctorsList);
}
export function* proctorsNgSessionsSaga() {
    yield all([watchGetProctors()]);
}
