import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
const Tooltip = ({ refDiv, children, position, isVisible, }) => {
    const absoluteDivRef = useRef(null);
    const referenceDivRef = useRef(null);
    const [showToolTip, setShowTooltip] = useState(isVisible);
    const [tooltipPosition, setTooltipPosition] = useState({});
    const [arrowPosition, setArrowPosition] = useState({});
    useEffect(() => {
        const element = referenceDivRef === null || referenceDivRef === void 0 ? void 0 : referenceDivRef.current;
        if (element) {
            element.addEventListener('mouseenter', onMouseEnter);
            element.addEventListener('mouseleave', onMouseLeave);
        }
        return () => {
            if (element) {
                element.removeEventListener('mouseenter', onMouseEnter);
                element.removeEventListener('mouseleave', onMouseLeave);
            }
        };
    }, [referenceDivRef]);
    const onMouseEnter = () => {
        setShowTooltip(true);
    };
    useEffect(() => {
        var _a, _b;
        if (showToolTip) {
            const rect = (_a = referenceDivRef === null || referenceDivRef === void 0 ? void 0 : referenceDivRef.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
            const tooltipRect = (_b = absoluteDivRef === null || absoluteDivRef === void 0 ? void 0 : absoluteDivRef.current) === null || _b === void 0 ? void 0 : _b.getBoundingClientRect();
            if (rect) {
                switch (position) {
                    case 'bottom-left':
                    case 'bottom-right':
                    case 'bottom-middle':
                        setArrowPosition({
                            top: `${rect.top + rect.height}px`,
                            left: `${rect.left + rect.width / 2 - 5.5}px`,
                        });
                        break;
                    case 'top-left':
                    case 'top-right':
                    case 'top-middle':
                    default:
                        setArrowPosition({
                            top: `${rect.top - 10}px`,
                            left: `${rect.left + rect.width / 2 - 5.5}px`,
                            rotate: '180deg',
                        });
                }
            }
            if (rect && tooltipRect) {
                switch (position) {
                    case 'top-left':
                        setTooltipPosition({
                            top: `${rect.top - tooltipRect.height - 10}px`,
                            left: `${rect.left + rect.width / 2 - (tooltipRect.width * 1) / 3}px`,
                        });
                        break;
                    case 'top-right':
                        setTooltipPosition({
                            top: `${rect.top - tooltipRect.height - 10}px`,
                            left: `${rect.left + rect.width / 2 - (tooltipRect.width * 2) / 3}px`,
                        });
                        break;
                    case 'bottom-left':
                        setTooltipPosition({
                            top: `${rect.top + rect.height + 5}px`,
                            left: `${rect.left + rect.width / 2 - (tooltipRect.width * 1) / 3}px`,
                        });
                        break;
                    case 'bottom-right':
                        setTooltipPosition({
                            top: `${rect.top + rect.height + 5}px`,
                            left: `${rect.left + rect.width / 2 - (tooltipRect.width * 2) / 3}px`,
                        });
                        break;
                    case 'bottom-middle':
                        setTooltipPosition({
                            top: `${rect.top + rect.height + 5}px`,
                            left: `${rect.left + rect.width / 2 - tooltipRect.width / 2}px`,
                        });
                        break;
                    case 'top-middle':
                    default:
                        setTooltipPosition({
                            top: `${rect.top - tooltipRect.height - 10}px`,
                            left: `${rect.left + rect.width / 2 - tooltipRect.width / 2}px`,
                        });
                }
            }
        }
    }, [showToolTip]);
    const onMouseLeave = () => {
        setShowTooltip(false);
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", { ref: referenceDivRef, className: "w-fit", children: refDiv }), showToolTip &&
                createPortal(_jsxs("div", { "data-testid": "tooltip", children: [_jsx("span", { ref: absoluteDivRef, className: "fixed z-10 w-fit p-2.5 text-xs leading-none text-white whitespace-nowrap bg-[#152029]/[0.9] rounded shadow-lg", style: Object.assign({}, tooltipPosition), children: children }), _jsx("div", { className: "fixed z-10 w-[11px] h-[5px] bg-[#152029]/[0.9]", style: Object.assign(Object.assign({}, arrowPosition), { clipPath: 'polygon(50% 0%, 0% 100%, 100% 100%)' }) })] }), document.body)] }));
};
export default Tooltip;
