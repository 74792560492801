import { defineMessages } from 'react-intl';
export const scope = 'app.components.proview_console_sessiond.sessions_table';
export default defineMessages({
    reviewed: {
        id: `${scope}.reviewed`,
        defaultMessage: 'Reviewed',
    },
    session_rating: {
        id: `${scope}.session_rating`,
        defaultMessage: 'Session Rating',
    },
});
