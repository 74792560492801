import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { faClockRotateLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover, Transition, PopoverButton, PopoverPanel, } from '@headlessui/react';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import { CheckBadgeIcon, InformationCircleIcon, NoSymbolIcon, } from '@heroicons/react/24/solid';
import { FormattedMessage } from 'react-intl';
import AssignProvider from '@components/AssignProvider';
import AccommodationBadges from '@components/Base/Accomodations';
import { Checkbox } from '@components/Base/Checkbox';
import IntegrityRating from '@components/Base/IntegrityRating';
import Link from '@components/Base/Link';
import Tooltip from '@components/Base/NewToolTip';
import Rating from '@components/Base/Rating';
import TextDescription from '@components/Base/TextDescription';
import User from '@components/Base/UserDetails';
import { convertToTitleCase } from '@components/ProctorNgSessions/SessionTable/helper';
import { providerList } from '@containers/sessions/types';
import { classNames } from '@utils/classNames';
import { formatDate, formatTimeFromMinutes, getDaysAgo, } from '@utils/dateFormat';
import messages from './messages';
import editIcon from '../../../images/edit-icon.svg';
import gripHorizontalIcon from '../../../images/grip-horizontal.svg';
export const getCustomColumns = {
    COORDINATOR: {
        sessions: [
            'reviews',
            'Attendee',
            'exam',
            'session_rating',
            'proctoring_type',
            'accomodations',
            'attempt',
            'status',
            'reason',
            'summary',
            'session_duration',
            'external_id',
            'completed_on',
            'menu',
        ],
        requests: [
            'request_attendee',
            'exam',
            'session_rating',
            'accomodations',
            'provider',
            'aging',
            'requested_on',
            'reason_for_request',
            'external_id',
            'proctoring_type',
            'edit',
            'delete',
        ],
    },
};
export const createColumns = ({ toggleCancelProviderModal, }) => [
    {
        id: 'reviews',
        header: () => '',
        accessorKey: 'reviews',
        enableSorting: false,
        cell: ({ row }) => {
            return row.original.session_reviews &&
                row.original.session_reviews.length > 0 &&
                row.original.session_reviews[0].review_status === 'REVIEWED' ? (_jsx(Tooltip, { refDiv: _jsx(CheckBadgeIcon, { className: "w-5 h-5 text-blue-700" }), children: _jsx("div", { children: _jsx(FormattedMessage, Object.assign({}, messages.reviewed)) }) })) : row.original.session_reviews &&
                row.original.session_reviews.length > 0 &&
                row.original.session_reviews[0].review_status === 'PENDING' ? (_jsx(Tooltip, { refDiv: _jsx(FontAwesomeIcon, { icon: faClockRotateLeft, className: "w-4 h-4 text-blue-700" }), children: _jsx("div", { children: "Review Pending" }) })) : (_jsx(_Fragment, {}));
        },
        size: 40,
        className: 'text-center left-12 sticky',
        cellClassName: 'text-center left-12 sticky z-[1]',
        hidden: false,
    },
    {
        id: 'Attendee',
        header: () => 'Attendee',
        accessorKey: 'Attendee',
        enableSorting: false,
        hidden: false,
        cell: ({ row }) => (_jsx(User, { data: {
                name: '',
                email: '',
            }, size: "sm", hideImage: true, additionalContent: row.original.candidate_id })),
        size: 227,
        className: 'text-left text-sm font-medium font-roboto text-gray-900 left-[88px] sticky attendee-column shadow-[inset_-1px_0px_0px_0px] shadow-gray-400/20',
        cellClassName: 'text-left sticky left-[88px] attendee-column shadow-[inset_-1px_0px_0px_0px] z-[1] shadow-gray-400/20',
    },
    {
        id: 'request_attendee',
        header: 'Attendee',
        accessorKey: 'request_attendee',
        enableSorting: false,
        cell: ({ row }) => {
            return (_jsx(User, { data: {
                    name: '',
                    email: '',
                }, size: "sm", hideImage: true, additionalContent: row.original.candidate_id }));
        },
        size: 208,
        className: 'text-left text-sm font-medium font-roboto text-gray-900 left-12 sticky attendee-column shadow-[inset_-1px_0px_0px_0px] shadow-gray-400/20',
        cellClassName: 'text-left sticky left-12 attendee-column shadow-[inset_-1px_0px_0px_0px] z-[1] shadow-gray-400/20',
    },
    {
        id: 'exam',
        header: () => 'Exam',
        accessorKey: 'session_candidate',
        enableSorting: false,
        cell: ({ row }) => (_jsx("div", { children: row.original.title ? (_jsx(Link, { title: row.original.title, description: row.original.uuid })) : (_jsx(_Fragment, { children: "-" })) })),
        size: 173,
        className: 'text-left text-sm font-medium font-roboto text-gray-900',
        cellClassName: 'text-left',
        hidden: false,
    },
    {
        id: 'session_rating',
        header: () => (_jsxs("div", { className: "flex align-middle", children: [_jsx("span", { className: "mr-1", children: _jsx(FormattedMessage, Object.assign({}, messages.session_rating)) }), _jsx(Tooltip, { refDiv: _jsx(InformationCircleIcon, { className: "w-5 h-5 text-gray-400" }), position: "bottom-right", children: _jsx(IntegrityRating, {}) })] })),
        accessorKey: 'session_candidate',
        enableSorting: false,
        cell: ({ row }) => {
            const flags = row.original.flags && row.original.flags.length > 0
                ? row.original.flags
                : [];
            const initialCounts = {
                critical: 0,
                high: 0,
                medium: 0,
                low: 0,
            };
            const classificationCounts = flags.reduce((acc, flag) => {
                const classificationText = flag.flag_type.flag_type_classification.text.toLowerCase();
                if (classificationText in acc) {
                    acc[classificationText] += 1;
                }
                return acc;
            }, initialCounts);
            return (_jsx("div", { className: "flex align-left", children: _jsx(Rating, Object.assign({ rating: row.original.system_rating
                        ? row.original.system_rating
                        : undefined }, (row.original.flags &&
                    row.original.flags.length > 0 && {
                    flags: classificationCounts,
                }), (row.original.session_reviews && {
                    statusHistory: [],
                }))) }));
        },
        size: 148,
        className: 'text-left text-sm font-medium font-roboto text-gray-900',
        cellClassName: 'text-left',
        hidden: false,
    },
    {
        id: 'proctoring_type',
        header: () => 'Proctoring Type',
        accessorKey: 'session_candidate',
        enableSorting: false,
        cell: ({ row }) => _jsx("div", { children: row.original.session_type }),
        size: 140,
        className: 'text-left text-sm font-medium font-roboto text-gray-900',
        cellClassName: 'text-left',
        hidden: false,
    },
    {
        id: 'accomodations',
        header: () => 'Accomodations',
        accessorKey: 'session_candidate',
        enableSorting: false,
        cell: ({ row }) => (_jsx(AccommodationBadges, { accommodations: row.original.accommodation })),
        size: 262,
        className: 'text-left text-sm font-medium font-roboto text-gray-900',
        cellClassName: 'text-left whitespace-nowrap',
        hidden: false,
    },
    {
        id: 'provider',
        header: () => 'Provider',
        accessorKey: 'provider',
        enableSorting: false,
        cell: () => _jsx("div", { children: "-" }),
        size: 133,
        className: 'text-left text-sm font-medium font-roboto text-gray-900',
        cellClassName: 'text-left',
        hidden: false,
    },
    {
        id: 'attempt',
        header: () => 'Attempt',
        accessorKey: 'session_candidate',
        enableSorting: false,
        cell: ({ row }) => (_jsx("div", { children: row.original.attempt_count ? row.original.attempt_count : '-' })),
        size: 95,
        className: 'text-center text-sm font-medium font-roboto text-gray-900',
        cellClassName: 'text-center',
        hidden: false,
    },
    {
        id: 'status',
        header: () => 'Status',
        accessorKey: 'session_candidate',
        enableSorting: false,
        cell: ({ row }) => _jsx("div", { children: row.original.session_status }),
        size: 133,
        className: 'text-left text-sm font-medium font-roboto text-gray-900',
        cellClassName: 'text-left',
        hidden: false,
    },
    {
        id: 'reason',
        header: () => 'Reason',
        accessorKey: 'session_candidate',
        enableSorting: false,
        cell: () => _jsx("div", { children: "-" }),
        size: 194,
        className: 'text-left text-sm font-medium font-roboto text-gray-900',
        cellClassName: 'text-left whitespace-nowrap',
        hidden: false,
    },
    {
        id: 'summary',
        header: () => 'Summary',
        accessorKey: 'session_candidate',
        enableSorting: false,
        cell: ({ row }) => {
            var _a;
            return (_jsx(TextDescription, { text: (_a = row.original.summary) !== null && _a !== void 0 ? _a : '-', showToolTip: true, maxNumberOfLines: 2, textClass: "w-[200px]" }));
        },
        size: 237,
        className: 'text-left text-sm font-medium font-roboto text-gray-900',
        cellClassName: 'text-left',
        hidden: false,
    },
    {
        id: 'session_duration',
        header: () => 'Session Duration',
        accessorKey: 'session_candidate',
        enableSorting: false,
        cell: ({ row }) => row.original.duration
            ? formatTimeFromMinutes(row.original.duration)
            : '-',
        size: 140,
        className: 'text-left text-sm font-medium font-roboto text-gray-900',
        cellClassName: 'text-left',
        hidden: false,
    },
    {
        id: 'external_id',
        header: () => 'External ID',
        accessorKey: 'session_candidate',
        enableSorting: false,
        cell: ({ row }) => _jsx("div", { children: row.original.external_id }),
        size: 124,
        className: 'text-left text-sm font-medium font-roboto text-gray-900',
        cellClassName: 'text-center text-sm font-normal',
        hidden: false,
    },
    {
        id: 'completed_on',
        header: () => 'Completed On',
        accessorKey: 'session_candidate',
        enableSorting: false,
        cell: ({ row }) => row.original.completed_at
            ? formatDate(new Date(row.original.completed_at), 'withTime')
            : '-',
        size: 203,
        className: 'text-left text-sm font-medium font-roboto text-gray-900',
        cellClassName: 'text-left text-sm/[19px] font-normal',
        hidden: false,
    },
    {
        id: 'aging',
        header: 'Aging',
        accessorKey: 'aging',
        enableSorting: false,
        size: 124,
        cell: ({ row }) => {
            var _a, _b;
            return row.original.session_reviews &&
                row.original.session_reviews.length > 0 &&
                ((_a = row.original.session_reviews[0]) === null || _a === void 0 ? void 0 : _a.assigned_at)
                ? getDaysAgo(new Date((_b = row.original.session_reviews[0]) === null || _b === void 0 ? void 0 : _b.assigned_at))
                : '-';
        },
        className: 'text-center text-sm font-medium font-roboto text-gray-900',
        cellClassName: 'text-center text-sm/[19px] font-normal pl-6',
    },
    {
        id: 'requested_on',
        header: 'Requested On',
        accessorKey: 'requested_on',
        enableSorting: false,
        cell: ({ row }) => {
            var _a;
            return row.original.session_reviews &&
                row.original.session_reviews.length > 0 &&
                ((_a = row.original.session_reviews[0]) === null || _a === void 0 ? void 0 : _a.assigned_at)
                ? formatDate(new Date(row.original.session_reviews[0].assigned_at), 'withTime')
                : '-';
        },
        size: 203,
        className: 'text-left text-sm font-medium font-roboto text-gray-900',
        cellClassName: 'text-left text-sm/[19px] font-normal',
    },
    {
        id: 'edit',
        header: '',
        accessorKey: 'edit',
        enableSorting: false,
        size: 47,
        cell: () => {
            const [isModalOpen, setIsModalOpen] = React.useState(false);
            const [hasInProgressSession] = React.useState(false);
            const handleCloseModal = () => {
                setIsModalOpen(false);
            };
            const handleRequestReview = () => { };
            const toggleOpenModal = () => {
                setIsModalOpen(!isModalOpen);
            };
            return (_jsxs(_Fragment, { children: [_jsx("div", { className: "w-5 h-5 cursor-pointer", "data-testid": "edit-request", onClick: toggleOpenModal, onKeyDown: (e) => {
                            if (e.key === 'Enter')
                                toggleOpenModal();
                        }, role: "button", tabIndex: 0, children: _jsx("img", { src: editIcon, alt: "edit", className: "w-full h-full" }) }), _jsx(AssignProvider, { data: providerList, headerText: "Edit Request", isEdit: true, openAddFlowModal: isModalOpen, handleCloseModal: handleCloseModal, onRequestReview: handleRequestReview, hasInProgressSession: hasInProgressSession })] }));
        },
    },
    {
        id: 'delete',
        header: '',
        accessorKey: 'delete',
        enableSorting: false,
        size: 47,
        cell: () => (_jsx("div", { children: _jsx(NoSymbolIcon, { className: "w-5 h-5 text-[#B91C1C] cursor-pointer", onClick: toggleCancelProviderModal }) })),
    },
    {
        id: 'menu',
        enableSorting: false,
        accessorKey: 'menu',
        size: 48,
        className: 'text-left right-0 sticky shadow-[inset_0px_0px_0px_-1px] shadow-gray-400/20',
        cellClassName: 'text-center right-0 sticky z-[1] shadow-[inset_0px_0px_0px_-1px] shadow-gray-400/20',
        header: ({ table }) => {
            return (_jsx("div", { children: _jsxs(Popover, { className: "flex justify-end items-center pr-3", children: [_jsx(PopoverButton, { className: classNames('focus:outline-none'), children: _jsx(PlusCircleIcon, { className: classNames('text-black h-5 w-5 focus:outline-none'), "aria-hidden": "true", "data-testid": "hide-column" }) }), _jsx(Transition, { children: _jsx(PopoverPanel, { children: _jsx("div", { className: "bg-white border-solid border-[1px] w-[214px] font-normal shadow-sm max-h-[480px] overflow-y-auto rounded-md px-4 absolute right-0 top-12", "data-testid": "column-model", children: table.getAllLeafColumns().map((column) => {
                                        if (column.id === 'menu' ||
                                            column.id === 'reviews' ||
                                            column.id === 'admin_reviews' ||
                                            column.id === 'selection') {
                                            return null;
                                        }
                                        return (_jsxs("div", { className: "h-[40px] w-full flex items-center", children: [_jsx("span", { className: "pr-2 w-6 h-6", children: _jsx("img", { src: gripHorizontalIcon, alt: "edit", className: "w-full h-full" }) }), _jsx(Checkbox, { label: typeof column.columnDef.header === 'string'
                                                        ? column.columnDef.header
                                                        : convertToTitleCase(column.id), id: column.id, size: "md", checked: column.getIsVisible(), onChange: column.getToggleVisibilityHandler(), disabled: false })] }, column.id));
                                    }) }) }) })] }) }));
        },
    },
    {
        id: 'reason_for_request',
        header: 'Reason For Request',
        accessorKey: 'reason_for_request',
        enableSorting: false,
        cell: ({ row }) => (_jsx(Tooltip, { refDiv: _jsx("div", { className: `overflow-hidden text-sm font-normal`, style: {
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 1,
                }, children: row.original.session_reviews &&
                    row.original.session_reviews.length > 0
                    ? row.original.session_reviews[0].review_request_reason
                    : '-' }), position: "top-left", children: _jsx("div", { className: "w-[269px] whitespace-normal leading-tight", children: row.original.session_reviews &&
                    row.original.session_reviews.length > 0
                    ? row.original.session_reviews[0].review_request_reason
                    : '-' }) })),
        size: 194,
        className: 'text-left text-sm font-medium font-roboto text-gray-900',
        cellClassName: 'text-left',
    },
];
