import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { FilterButton } from '@components/Flows/Filters/FilterButton';
import Filters from '@components/ProviewConsoleSessions/Filters';
import { USER_ROLE, SESSION_TABS } from '@utils/data/enums';
const SessionFilters = (props) => {
    const { updateAppliedFilters, userRole, selectedTab, examList } = props;
    const [showFilter, setShowFilter] = useState(false);
    const toogleFilterView = () => {
        setShowFilter((prev) => !prev);
    };
    const isShowSessionStatus = userRole === USER_ROLE.COORDINATOR &&
        selectedTab === SESSION_TABS.ALL_SESSIONS;
    const isShowCompletedOnDate = userRole === USER_ROLE.COORDINATOR &&
        selectedTab === SESSION_TABS.ALL_SESSIONS;
    const isShowRequestedOnDate = userRole === USER_ROLE.COORDINATOR && selectedTab === SESSION_TABS.REQUESTS;
    const showProvider = userRole === USER_ROLE.COORDINATOR && selectedTab === SESSION_TABS.REQUESTS;
    return (_jsxs(_Fragment, { children: [_jsx(FilterButton, { hasAppliedFilters: false, onClick: toogleFilterView }), _jsx(Filters, { updateAppliedFilters: updateAppliedFilters, showFilter: showFilter, toogleFilterView: toogleFilterView, isShowSessionStatus: isShowSessionStatus, isShowCompletedOnDate: isShowCompletedOnDate, isShowRequestedOnDate: isShowRequestedOnDate, showProvider: showProvider, examList: examList })] }));
};
export default SessionFilters;
