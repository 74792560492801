import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FormattedMessage } from 'react-intl';
import { ALVY } from '@utils/data/enums';
import Sparkles from 'app/images/svgs/sparkles.svg';
import messages from './messages';
import RatingAtom from './RatingAtom';
const StatusHistory = ({ statusHistory }) => {
    const formatDateHelper = (dateString) => {
        const date = new Date(dateString);
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        };
        const formattedDate = date.toLocaleDateString('en-GB', options);
        const timeOptions = {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        };
        const formattedTime = date.toLocaleTimeString('en-US', timeOptions);
        return `${formattedDate} at ${formattedTime}`;
    };
    return (_jsxs("div", { className: "z-1 -right-64 -top-4 absolute flex flex-col box-border rounded-lg border border-gray-200 shadow-[0_4px_32px_0_rgba(17,24,39,0.12)] bg-white px-3 py-2 text-wrap", children: [_jsx("span", { className: "text-[#3f3d3c] font-roboto font-medium text-sm", children: _jsx(FormattedMessage, Object.assign({}, messages.statusHistory)) }), _jsx("div", { className: "flex flex-col items-center", children: statusHistory === null || statusHistory === void 0 ? void 0 : statusHistory.map((status, index) => (_jsxs(_Fragment, { children: [_jsxs("div", { className: "flex justify-between py-2", children: [_jsx("div", { className: "flex w-[86px]", children: _jsx(RatingAtom, { rating: status.status }) }), _jsxs("div", { className: "inline-flex flex-col font-roboto justify-end text-left w-[120px]", children: [_jsxs("div", { className: "flex text-xs/[20px] text-slate-800 font-medium", children: [status.updated_by, status.updated_by === ALVY ? (_jsx("img", { src: Sparkles, alt: "sparkles", className: "w-3.5 m-0.5 ml-1" })) : null] }), _jsx("div", { className: "text-[10px]/[12px] text-slate-700 font-normal", children: formatDateHelper(status.updated_at) }), _jsx("div", { className: "text-gray-500 text-[8px]", children: status.description })] })] }, index), index !== statusHistory.length - 1 && (_jsx("div", { className: "border-t-[0.5px] border-[#e7e7e7] w-[180px] h-0 self-start" }))] }))) })] }));
};
export default StatusHistory;
