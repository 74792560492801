import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import CalendarView from '@components/Shared/DateRangePicker/CalenderView/Calender';
import Header from '@components/Shared/DateRangePicker/Header';
const CalenderView = (props) => {
    const { inputDateRange, toCalendarDefaultDate, handleUpdateToCalendarDefaultDate, handleCustomSelect, handleToUpdateCustomRangeOfDates, getNextMonthDate, disabledDateFrom, disablePastDate, isReadOnly, disabledWeekend, minDate, maxDate, isDateRangePicker, } = props;
    const { startDate, endDate } = inputDateRange;
    const [showDateView, setShowDateView] = React.useState(true);
    return (_jsxs("div", { className: "w-[224px]", children: [_jsx(Header, { selectedMonth: toCalendarDefaultDate
                    ? toCalendarDefaultDate.getMonth()
                    : getNextMonthDate().getMonth(), selectedYear: toCalendarDefaultDate
                    ? toCalendarDefaultDate.getFullYear()
                    : new Date().getFullYear(), onMonthSelect: (date) => {
                    handleUpdateToCalendarDefaultDate({
                        startDate: startDate,
                        endDate: date,
                    });
                }, onYearSelect: (date) => {
                    handleUpdateToCalendarDefaultDate({
                        startDate: startDate,
                        endDate: date,
                    });
                }, defaultDate: toCalendarDefaultDate || getNextMonthDate(), toggleMonth: (date) => {
                    handleUpdateToCalendarDefaultDate({
                        startDate: startDate,
                        endDate: date,
                    });
                }, handleDateView: (isDisplay) => {
                    setShowDateView(isDisplay);
                } }), showDateView && (_jsx(CalendarView, { date: toCalendarDefaultDate || getNextMonthDate(), selectedDate: null, startDate: startDate, endDate: endDate, disabledDateFrom: disabledDateFrom, onDateSelect: (date) => {
                    handleToUpdateCustomRangeOfDates(date);
                    handleCustomSelect();
                }, disablePastDate: disablePastDate, isReadOnly: isReadOnly, disabledWeekend: disabledWeekend, minDate: minDate, maxDate: maxDate, isDateRangePicker: isDateRangePicker }))] }));
};
export default CalenderView;
