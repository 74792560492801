import { combineReducers } from '@reduxjs/toolkit';
import appReducer from '@containers/app/slice';
import flowConfigReducer from '@containers/flowDetails/slice';
import flowsReducer from '@containers/flowList/slice';
import projectsReducer from '@containers/projects/slice';
import sessionListReducer from '@containers/sessions/proctor_ng/slice';
import consoleSessionsReducer from '@containers/sessions/proview_console/slice';
import userListReducer from '@containers/user/slice';
const rootReducers = combineReducers({
    app: appReducer,
    projects: projectsReducer,
    flows: flowsReducer,
    flowConfig: flowConfigReducer,
    userList: userListReducer,
    sessionsNg: sessionListReducer,
    consoleSessions: consoleSessionsReducer,
});
export default rootReducers;
