import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { getDaysInMonth } from '@components/Shared/DatePicker/helper';
import { classNames } from '@utils/classNames';
const daysOfWeek = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];
const getCorrectFirstDayOfMonth = (year, month) => {
    const day = new Date(year, month, 1).getDay();
    return day === 0 ? 6 : day - 1;
};
const CalendarView = ({ date, startDate, endDate, onDateSelect, disablePastDate, isReadOnly, minDate, maxDate, disabledWeekend, disabledDateFrom, isDateRangePicker, selectedDate, }) => {
    const [selectedDay, setSelectedDay] = useState(selectedDate);
    const currentDate = new Date();
    const currentDay = currentDate.getDate();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    const isPastDate = (year, month, day) => {
        const date = new Date(year, month, day);
        return date < new Date(currentYear, currentMonth, currentDay);
    };
    const isBeforeMinDate = (date) => {
        return minDate ? date < minDate : false;
    };
    const isAfterMaxDate = (date) => {
        return maxDate ? date > maxDate : false;
    };
    const isWeekend = (year, month, day) => {
        const date = new Date(year, month, day);
        const dayOfWeek = date.getDay();
        return dayOfWeek === 6 || dayOfWeek === 0;
    };
    const handleDateClick = (day) => {
        const newSelectedDate = new Date(date.getFullYear(), date.getMonth(), day);
        if ((disablePastDate &&
            isPastDate(date.getFullYear(), date.getMonth(), day)) ||
            (disabledDateFrom &&
                isDateRangePicker &&
                newSelectedDate < disabledDateFrom) ||
            isReadOnly ||
            isBeforeMinDate(newSelectedDate) ||
            isAfterMaxDate(newSelectedDate) ||
            (disabledWeekend && isWeekend(date.getFullYear(), date.getMonth(), day))) {
            return;
        }
        if (!isDateRangePicker) {
            setSelectedDay(newSelectedDate);
        }
        onDateSelect(newSelectedDate);
    };
    const handleDateKeyDown = (event, day) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            handleDateClick(day);
        }
    };
    const year = date.getFullYear();
    const month = date.getMonth();
    const firstDayOfMonth = getCorrectFirstDayOfMonth(year, month);
    const daysInMonth = getDaysInMonth(year, month);
    const isDateInRange = (presentDay) => {
        if (startDate === null || endDate === null) {
            return false;
        }
        return startDate <= presentDay && presentDay <= endDate;
    };
    const renderDays = () => {
        const days = [];
        for (let i = 0; i < firstDayOfMonth; i++) {
            days.push(_jsx("div", { className: "flex items-center justify-center w-8 h-8" }, `empty-start-${i}`));
        }
        for (let day = 1; day <= daysInMonth; day++) {
            const isCurrentDay = day === currentDay && month === currentMonth && year === currentYear;
            const isSelectedDay = selectedDay !== null &&
                day === selectedDay.getDate() &&
                month === selectedDay.getMonth() &&
                year === selectedDay.getFullYear();
            const isStartDate = startDate !== null &&
                day === startDate.getDate() &&
                month === startDate.getMonth() &&
                year === startDate.getFullYear();
            const isEndDate = endDate !== null &&
                day === endDate.getDate() &&
                month === endDate.getMonth() &&
                year === endDate.getFullYear();
            const currentDate = new Date(year, month, day);
            const isDisabled = (disablePastDate && isPastDate(year, month, day)) ||
                (disabledDateFrom && currentDate < disabledDateFrom) ||
                isReadOnly ||
                isBeforeMinDate(currentDate) ||
                isAfterMaxDate(currentDate) ||
                (disabledWeekend && isWeekend(year, month, day));
            if (isDateRangePicker) {
                days.push(_jsx("div", { onClick: () => handleDateClick(day), onKeyDown: (e) => handleDateKeyDown(e, day), role: isReadOnly ? 'presentation' : 'button', tabIndex: isDisabled ? -1 : 0, "aria-label": `Day ${day}`, "aria-disabled": isReadOnly || isDisabled, "data-testid": isDisabled ? 'date-disabled' : 'date-enabled', className: classNames('flex items-center justify-center cursor-pointer text-sm w-8 h-8 rounded-full relative', {
                        'bg-blue-700 ': isCurrentDay && isStartDate,
                        'bg-blue-700/5': isCurrentDay,
                        'bg-blue-700 text-white': isStartDate || isEndDate,
                        'after:content-[""] after:absolute after:bottom-0 after:left-0 after:right-0 after:top-0 after:bg-blue-700 after:opacity-5 after:z-[-1]': isDateInRange(currentDate),
                        'hover:bg-gray-200': !isCurrentDay && !isStartDate && !isEndDate && !isReadOnly,
                        'cursor-not-allowed opacity-50': isDisabled,
                    }), children: day }, day));
            }
            else {
                days.push(_jsx("div", { onClick: () => handleDateClick(day), onKeyDown: (e) => handleDateKeyDown(e, day), role: isReadOnly ? 'presentation' : 'button', tabIndex: isDisabled ? -1 : 0, "aria-label": `Day ${day}`, "aria-disabled": isReadOnly || isDisabled, "data-testid": isDisabled ? 'date-disabled' : 'date-enabled', className: classNames('flex items-center justify-center cursor-pointer text-sm w-8 h-8 rounded-full', {
                        'bg-blue-700 ': isCurrentDay && isSelectedDay,
                        'bg-blue-700/5': isCurrentDay,
                        'bg-blue-700 text-white': isSelectedDay,
                        'hover:bg-gray-200': !isCurrentDay && !isSelectedDay && !isReadOnly,
                        'cursor-not-allowed opacity-50': isDisabled,
                    }), children: day }, day));
            }
        }
        return days;
    };
    return (_jsxs("div", { className: "flex flex-col w-full h-fit", "data-testid": "calendar-view", children: [_jsx("div", { className: "grid grid-cols-7 gap-1", children: daysOfWeek.map((day) => (_jsx("div", { className: "flex items-center justify-center text-center font-medium text-sm text-gray-500 w-8 h-8", children: day }, day))) }), _jsx("div", { className: "flex-1 mt-1", children: _jsx("div", { className: "grid grid-cols-7 text-gray-900", children: renderDays() }) })] }));
};
export default CalendarView;
