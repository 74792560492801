import { createSlice } from '@reduxjs/toolkit';
export const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
    error: null,
    data: [],
};
export const consoleSessions = createSlice({
    name: 'consoleSessions',
    initialState: initialState,
    reducers: {
        getProSessionsAction: (state, _action) => {
            state.isLoading = true;
        },
        getProSessionsSuccessAction: (state, { payload: payload }) => {
            state.data = payload;
            state.isSuccess = true;
            state.isLoading = false;
        },
        getProSessionsErrorAction: (state, { payload: error }) => {
            state.isLoading = false;
            state.isError = true;
            state.error = error;
        },
    },
});
export const { getProSessionsAction, getProSessionsSuccessAction, getProSessionsErrorAction, } = consoleSessions.actions;
export default consoleSessions.reducer;
