import { defineMessages } from 'react-intl';
export const scope = 'app.component.integrity_rating';
export default defineMessages({
    high: {
        id: `${scope}.high`,
        defaultMessage: 'High',
    },
    high_description: {
        id: `${scope}.high_description`,
        defaultMessage: 'No major issues detected; high test integrity.',
    },
    medium: {
        id: `${scope}.medium`,
        defaultMessage: 'Medium',
    },
    medium_description: {
        id: `${scope}.medium_description`,
        defaultMessage: 'Minor irregularities; test integrity mostly maintained.',
    },
    low: {
        id: `${scope}.low`,
        defaultMessage: 'Low',
    },
    low_description: {
        id: `${scope}.low_description`,
        defaultMessage: 'Significant infractions; test integrity compromised.',
    },
});
