import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useRef, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@components/Base/Button';
import { RANGE_LIST } from '@components/Shared/DatePicker/enums';
import RangeList from '@components/Shared/DatePicker/RangeList';
import FirstCalenderView from '@components/Shared/DateRangePicker/CalenderView/FirstCalender';
import SecondCalenderView from '@components/Shared/DateRangePicker/CalenderView/SecondCalender';
import DateRangeInputBox from '@components/Shared/DateRangePicker/InputBox';
import messages from './messages';
const DateRangePicker = (props) => {
    const { disablePastDate = false, isReadOnly = false, disabledWeekend = false, onClickOnDonebtn, minDate, maxDate, isDateRangePicker = true, } = props;
    const [rangeOfDates, setRangeOfDates] = useState({
        startDate: null,
        endDate: null,
    });
    const referenceDivRef = useRef(null);
    const absoluteDivRef = useRef(null);
    const [position, setPosition] = useState({ top: 10, right: 0 });
    const [inputDateRange, setInputDateRange] = useState({
        startDate: null,
        endDate: null,
    });
    const [toInputDateRange, setToInputDateRange] = useState({
        startDate: null,
        endDate: null,
    });
    const { startDate, endDate } = inputDateRange;
    const [defaultDateToRenderCalender, setDefaultDateToRenderCalender] = useState(null);
    const [toCalendarDefaultDate, setToCalendarDefaultDate] = useState(null);
    const [selectedRangeList, setSelectedRangeList] = useState(null);
    const [hasOpenContainer, setHasOpenContainer] = useState(false);
    const handleCalendarIconClick = () => {
        setHasOpenContainer((prev) => !prev);
    };
    const handleCloseContainer = () => {
        setHasOpenContainer(false);
    };
    const handleUpdateDefaultDate = (range) => {
        setDefaultDateToRenderCalender(range.startDate);
        if (range.startDate) {
            const nextMonth = range.startDate.getMonth() + 2;
            const currentYear = range.startDate.getFullYear();
            const date = new Date(currentYear, nextMonth, 0);
            setToCalendarDefaultDate(date);
        }
    };
    const handleUpdateToCalendarDefaultDate = (range) => {
        setToCalendarDefaultDate(range.endDate);
    };
    const handleUpadteInputDatesToCalender = (range) => {
        setInputDateRange({
            startDate: range.startDate,
            endDate: range.endDate,
        });
    };
    const handleUpdateInputDatesToCalenderTwo = (range) => {
        setToInputDateRange({
            startDate: range.startDate,
            endDate: range.endDate,
        });
    };
    const handleCustomSelect = () => {
        setSelectedRangeList(RANGE_LIST.CUSTOM);
    };
    const getNextMonthDate = () => {
        const currentDate = new Date();
        const nextMonthDate = new Date(currentDate);
        nextMonthDate.setMonth(currentDate.getMonth() + 1);
        return nextMonthDate;
    };
    const handleToUpdateCustomRangeOfDates = (date) => {
        const range = {
            startDate: startDate,
            endDate: endDate,
        };
        if (!startDate || (startDate && date < startDate)) {
            // If there's no start date or the date is before start date
            range.startDate = date;
        }
        else if (!endDate || (endDate && date > endDate)) {
            // If there's no end date or the date is after end date
            range.endDate = date;
        }
        else if (date > startDate && date < endDate) {
            // If date is between startDate and endDate, find the nearest one
            const diffToStart = Math.abs(date.getTime() - startDate.getTime());
            const diffToEnd = Math.abs(date.getTime() - endDate.getTime());
            if (diffToStart < diffToEnd) {
                range.startDate = date;
            }
            else {
                range.endDate = date;
            }
        }
        handleUpadteInputDatesToCalender(range);
        handleUpdateInputDatesToCalenderTwo(range);
    };
    useEffect(() => {
        if (referenceDivRef.current && hasOpenContainer) {
            const referenceRect = referenceDivRef.current.getBoundingClientRect();
            const newPosition = {
                top: referenceRect.top - 445,
                right: 0,
            };
            setPosition(newPosition);
        }
    }, [hasOpenContainer]);
    const handleClickDoneBtn = () => {
        setRangeOfDates({
            startDate: startDate,
            endDate: endDate,
        });
        onClickOnDonebtn({
            startDate: startDate,
            endDate: endDate,
        });
        handleCloseContainer();
    };
    const handleReset = () => {
        setRangeOfDates({
            startDate: null,
            endDate: null,
        });
        setToInputDateRange({
            startDate: null,
            endDate: null,
        });
        setToCalendarDefaultDate(null);
        setInputDateRange({
            startDate: null,
            endDate: null,
        });
        setDefaultDateToRenderCalender(null);
        setSelectedRangeList(null);
    };
    return (_jsxs("div", { children: [_jsx("div", { ref: referenceDivRef, className: "w-full h-full", children: _jsx(DateRangeInputBox, { onDateChange: (range) => {
                        handleUpadteInputDatesToCalender({
                            startDate: range.from,
                            endDate: range.to,
                        });
                        handleUpdateInputDatesToCalenderTwo({
                            startDate: range.from,
                            endDate: range.to,
                        });
                        handleUpdateDefaultDate({
                            startDate: range.from,
                            endDate: range.to,
                        });
                        handleUpdateToCalendarDefaultDate({
                            startDate: range.from,
                            endDate: range.to,
                        });
                    }, setSelectedRangeList: setSelectedRangeList, fromDate: rangeOfDates.startDate, toDate: rangeOfDates.endDate, onIconClick: handleCalendarIconClick, onInputFocus: handleCloseContainer }) }), hasOpenContainer && (_jsxs("div", { className: `flex p-4 mt-2 w-[664px] h-[348px] rounded-md shadow-[0px_1px_3px_0px_rgba(0,_0,_0,_0.10)] bg-white absolute right-6 z-50`, "data-testid": "calender-view-container", ref: absoluteDivRef, style: {
                    top: `${position.top}px`,
                }, children: [_jsx(RangeList, { setSelectedRangeList: setSelectedRangeList, selectedRangeList: selectedRangeList, onDateRangeSelect: (range) => {
                            handleUpadteInputDatesToCalender(range);
                            setDefaultDateToRenderCalender(range.startDate);
                            handleUpdateInputDatesToCalenderTwo(range);
                            if (range.startDate) {
                                const nextMonth = range.startDate.getMonth() + 2;
                                const currentYear = range.startDate.getFullYear();
                                const date = new Date(currentYear, nextMonth, 0);
                                setToCalendarDefaultDate(date);
                            }
                        } }), _jsx("div", { className: "flex-col justify-center w-[1px] h-[316px] mr-4 bg-gray-200" }), _jsxs("div", { children: [_jsxs("div", { className: "flex gap-6 h-[260px]", children: [_jsx(FirstCalenderView, { inputDateRange: inputDateRange, defaultDateToRenderCalender: defaultDateToRenderCalender, handleUpdateDefaultDate: handleUpdateDefaultDate, handleCustomSelect: handleCustomSelect, handleToUpdateCustomRangeOfDates: handleToUpdateCustomRangeOfDates, disablePastDate: disablePastDate, isReadOnly: isReadOnly, disabledWeekend: disabledWeekend, minDate: minDate, maxDate: maxDate, isDateRangePicker: isDateRangePicker }), _jsx(SecondCalenderView, { inputDateRange: toInputDateRange, disabledDateFrom: startDate, toCalendarDefaultDate: toCalendarDefaultDate, handleUpdateToCalendarDefaultDate: handleUpdateToCalendarDefaultDate, handleCustomSelect: handleCustomSelect, handleToUpdateCustomRangeOfDates: handleToUpdateCustomRangeOfDates, getNextMonthDate: getNextMonthDate, disablePastDate: disablePastDate, isReadOnly: isReadOnly, disabledWeekend: disabledWeekend, minDate: minDate, maxDate: maxDate, isDateRangePicker: isDateRangePicker })] }), _jsxs("div", { className: "flex flex-row-reverse mt-4 gap-3", children: [_jsx(Button, { variant: "base", className: "w-24", disabled: !startDate || !endDate, onClick: handleClickDoneBtn, children: _jsx(FormattedMessage, Object.assign({}, messages.done)) }), _jsx(Button, { variant: "outline", className: "w-24", disabled: !startDate && !endDate, onClick: handleReset, children: _jsx(FormattedMessage, Object.assign({}, messages.clear)) })] })] })] }))] }));
};
export default DateRangePicker;
