import { defineMessages } from 'react-intl';
export const scope = 'app.components.flows.filters';
export default defineMessages({
    filters_button: {
        id: `${scope}.filters_button`,
        defaultMessage: 'Filters',
    },
    filter: {
        id: `${scope}.filter`,
        defaultMessage: 'Filter',
    },
    customize_view: {
        id: `${scope}.customize_view`,
        defaultMessage: 'Customize your view by selectively including or excluding table elements.',
    },
    select: {
        id: `${scope}.select`,
        defaultMessage: 'Select..',
    },
    select_exam: {
        id: `${scope}.select_exam`,
        defaultMessage: 'Select exams',
    },
    select_tenant: {
        id: `${scope}.select_tenant`,
        defaultMessage: 'Select tenants',
    },
    select_status: {
        id: `${scope}.select_status`,
        defaultMessage: 'Select status',
    },
    select_accoummodations: {
        id: `${scope}.select_accoummodations`,
        defaultMessage: 'Select accommodations',
    },
    status: {
        id: `${scope}.status`,
        defaultMessage: 'Status',
    },
    last_updated_by: {
        id: `${scope}.last_updated_by`,
        defaultMessage: 'Last Updated By',
    },
    last_updated_date: {
        id: `${scope}.last_updated_date`,
        defaultMessage: 'Last Updated Date',
    },
    clear_filters: {
        id: `${scope}.clear_filters`,
        defaultMessage: 'Clear Filters',
    },
    apply: {
        id: `${scope}.apply`,
        defaultMessage: 'Apply',
    },
    proctoring_type: {
        id: `${scope}.proctoring_type`,
        defaultMessage: 'Proctoring type',
    },
    session_rating: {
        id: `${scope}.session_rating`,
        defaultMessage: 'Session rating',
    },
    session_status: {
        id: `${scope}.session_status`,
        defaultMessage: 'Session status',
    },
    review_status: {
        id: `${scope}.review_status`,
        defaultMessage: 'Review status',
    },
    exam: {
        id: `${scope}.exam`,
        defaultMessage: 'Exam',
    },
    tenant: {
        id: `${scope}.tenant`,
        defaultMessage: 'Tenant',
    },
    accomodation: {
        id: `${scope}.accomodation`,
        defaultMessage: 'Accomodation',
    },
    completed_on_date: {
        id: `${scope}.completed_on_date`,
        defaultMessage: 'Completed on date',
    },
    requested_on_date: {
        id: `${scope}.requested_on_date`,
        defaultMessage: 'Requested on date',
    },
    proctor: {
        id: `${scope}.proctor`,
        defaultMessage: 'Proctor',
    },
    select_proctor: {
        id: `${scope}.select_proctor`,
        defaultMessage: 'Select proctor',
    },
});
