import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import CalendarView from '@components/Shared/DateRangePicker/CalenderView/Calender';
import Header from '@components/Shared/DateRangePicker/Header';
const CalenderView = (props) => {
    const { inputDateRange, defaultDateToRenderCalender, handleUpdateDefaultDate, handleCustomSelect, handleToUpdateCustomRangeOfDates, disablePastDate, isReadOnly, disabledWeekend, minDate, maxDate, isDateRangePicker, } = props;
    const { startDate, endDate } = inputDateRange;
    const [showDateView, setShowDateView] = useState(true);
    return (_jsxs("div", { className: "w-[224px]", children: [_jsx(Header, { selectedMonth: defaultDateToRenderCalender
                    ? defaultDateToRenderCalender.getMonth()
                    : new Date().getMonth(), selectedYear: defaultDateToRenderCalender
                    ? defaultDateToRenderCalender.getFullYear()
                    : new Date().getFullYear(), onMonthSelect: (date) => {
                    handleUpdateDefaultDate({
                        startDate: date,
                        endDate: null,
                    });
                }, onYearSelect: (date) => {
                    handleUpdateDefaultDate({
                        startDate: date,
                        endDate: null,
                    });
                }, defaultDate: defaultDateToRenderCalender, toggleMonth: (date) => {
                    handleUpdateDefaultDate({
                        startDate: date,
                        endDate: null,
                    });
                }, handleDateView: (isDisplay) => {
                    setShowDateView(isDisplay);
                } }), showDateView && (_jsx(CalendarView, { date: defaultDateToRenderCalender || new Date(), selectedDate: null, startDate: startDate, endDate: endDate, onDateSelect: (date) => {
                    handleToUpdateCustomRangeOfDates(date);
                    handleCustomSelect();
                }, disablePastDate: disablePastDate, isReadOnly: isReadOnly, disabledWeekend: disabledWeekend, minDate: minDate, maxDate: maxDate, isDateRangePicker: isDateRangePicker }))] }));
};
export default CalenderView;
