import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { faClock } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { formatDate } from '@utils/dateFormat';
import messages from './messages';
function LastLogin(props) {
    const { data: lastSignInDate } = props;
    const lastLogInTime = useMemo(() => {
        const parsedDate = lastSignInDate
            ? formatDate(new Date(lastSignInDate), 'withTime')
            : null;
        return parsedDate;
    }, [lastSignInDate]);
    return (_jsx("div", { className: "border rounded-md p-2 bg-gray-100", children: _jsxs("div", { className: "flex items-center text-gray-500 ", children: [_jsx(FontAwesomeIcon, { icon: faClock, className: "w-4 rounded-full pr-1" }), _jsxs("span", { className: "text-base ml-1", children: [_jsx(FormattedMessage, Object.assign({}, messages.last_login)), _jsxs("span", { className: "ml-0.5", children: [" ", lastLogInTime || '-'] })] })] }) }));
}
export default LastLogin;
