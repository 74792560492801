import { defineMessages } from 'react-intl';
export const scope = 'app.components.sessions_list.integrity_rating';
export default defineMessages({
    high: {
        id: `${scope}.high`,
        defaultMessage: 'High',
    },
    medium: {
        id: `${scope}.medium`,
        defaultMessage: 'Medium',
    },
    low: {
        id: `${scope}.low`,
        defaultMessage: 'Low',
    },
    critical_alerts: {
        id: `${scope}.critical_alerts`,
        defaultMessage: 'Critical alerts',
    },
    statusHistory: {
        id: `${scope}.statusHistory`,
        defaultMessage: 'Status history',
    },
});
