import { jsx as _jsx } from "react/jsx-runtime";
import { faClipboardList, faFolder, faShapes, faUserGear, faCalendarCirclePlus, faPersonShelter, faCalendarPlus, faCircleDollar, faGraduationCap, } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HomeIcon, UsersIcon, VideoCameraIcon, } from '@heroicons/react/24/outline';
import { FormattedMessage } from 'react-intl';
import { getUserMasterPermissions } from '@utils/permissions/userPermission';
import messages from './messages';
import SettingsNavItem from './settings';
import Flow from '../../../images/svgs/flow.svg';
const userPermission = getUserMasterPermissions();
export const navSection = [
    {
        id: 'navSection1',
        icon: _jsx(HomeIcon, { className: "w-6 h-6" }),
        label: _jsx(FormattedMessage, Object.assign({}, messages.home)),
        path: '/home',
        showNavSection: typeof userPermission.Home.canView === 'undefined'
            ? true
            : userPermission.Home.canView,
    },
    {
        id: 'navSection2',
        icon: (_jsx("div", { className: "w-6 h-6", children: _jsx(FontAwesomeIcon, { icon: faFolder, className: "w-5 h-5" }) })),
        label: _jsx(FormattedMessage, Object.assign({}, messages.projects)),
        path: '/projects',
        showNavSection: typeof userPermission.Projects.canView === 'undefined'
            ? true
            : userPermission.Projects.canView,
    },
    {
        id: 'navSection3',
        icon: _jsx(FontAwesomeIcon, { icon: faCalendarCirclePlus, className: "w-6 h-6" }),
        label: _jsx(FormattedMessage, Object.assign({}, messages.events)),
        path: '',
        showNavSection: typeof userPermission.Events.canView === 'undefined'
            ? true
            : userPermission.Events.canView,
    },
    {
        id: 'navSection4',
        icon: _jsx("img", { src: Flow, alt: "Flow", className: "min-w-6 min-h-6" }),
        label: _jsx(FormattedMessage, Object.assign({}, messages.flows)),
        path: '/flows',
        showNavSection: false,
    },
    {
        id: 'navSection5',
        icon: _jsx(VideoCameraIcon, { className: "w-6 h-6" }),
        label: _jsx(FormattedMessage, Object.assign({}, messages.sessions)),
        path: '/sessions',
        showNavSection: typeof userPermission.Sessions.canView === 'undefined'
            ? true
            : userPermission.Sessions.canView,
    },
    {
        id: 'navSection6',
        icon: _jsx(FontAwesomeIcon, { icon: faGraduationCap, className: "w-6 h-6" }),
        label: _jsx(FormattedMessage, Object.assign({}, messages.exams)),
        path: '',
        showNavSection: typeof userPermission.Exams.canView === 'undefined'
            ? true
            : userPermission.Exams.canView,
    },
    {
        id: 'navSection7',
        icon: _jsx(FontAwesomeIcon, { icon: faUserGear, className: "w-6 h-6" }),
        label: _jsx(SettingsNavItem, {}),
        path: '',
        isArrow: true,
        showNavSection: typeof userPermission.Settings.canView === 'undefined'
            ? true
            : userPermission.Settings.canView,
    },
    {
        id: 'navSection8',
        icon: (_jsx("div", { className: "w-6 h-6", children: _jsx(FontAwesomeIcon, { icon: faCircleDollar, className: "w-5 h-5" }) })),
        label: _jsx(FormattedMessage, Object.assign({}, messages.payments)),
        path: '',
        showNavSection: typeof userPermission.Payments.canView === 'undefined'
            ? true
            : userPermission.Payments.canView,
    },
    {
        id: 'navSection9',
        icon: _jsx(UsersIcon, { className: "w-6 h-6" }),
        label: _jsx(FormattedMessage, Object.assign({}, messages.proctors)),
        path: '',
        showNavSection: typeof userPermission.Proctors.canView === 'undefined'
            ? true
            : userPermission.Proctors.canView,
    },
    {
        id: 'navSection10',
        icon: _jsx(FontAwesomeIcon, { icon: faCalendarPlus, className: "w-6 h-6" }),
        label: _jsx(FormattedMessage, Object.assign({}, messages.schedule)),
        path: '',
        showNavSection: typeof userPermission.Schedule.canView === 'undefined'
            ? true
            : userPermission.Schedule.canView,
    },
    {
        id: 'navSection11',
        icon: _jsx(FontAwesomeIcon, { icon: faPersonShelter, className: "w-6 h-6" }),
        label: _jsx(FormattedMessage, Object.assign({}, messages.rooms)),
        path: '',
        showNavSection: typeof userPermission.Rooms.canView === 'undefined'
            ? true
            : userPermission.Rooms.canView,
    },
    {
        id: 'navSection9',
        icon: _jsx(UsersIcon, { className: "w-6 h-6" }),
        label: _jsx(FormattedMessage, Object.assign({}, messages.attendees)),
        path: '/attendees',
        showNavSection: true,
    },
    {
        id: 'navSection10',
        icon: _jsx(FontAwesomeIcon, { icon: faClipboardList, className: "w-6 h-6" }),
        label: _jsx(FormattedMessage, Object.assign({}, messages.reports)),
        path: '/reports',
        showNavSection: true,
    },
    {
        id: 'navSection11',
        icon: _jsx(FontAwesomeIcon, { icon: faShapes, className: "w-6 h-6" }),
        label: _jsx(FormattedMessage, Object.assign({}, messages.more_apps)),
        path: '/more-apps',
        isArrow: true,
        showNavSection: true,
    },
];
