import { all, call, put, takeLatest } from 'redux-saga/effects';
import fetchData from '@utils/fetchData';
import { GET_PRO_SESSION_QUERY } from './queries';
import { getProSessionsAction, getProSessionsErrorAction, getProSessionsSuccessAction, } from './slice';
export function* getSessionDetails(action) {
    const { conditions } = action.payload;
    const queryVariables = {
        limit: 10,
        offset: 0,
        conditions: Object.assign({}, conditions),
    };
    try {
        const response = yield call(fetchData, {
            queryString: GET_PRO_SESSION_QUERY,
            queryKey: 'pro_session',
            queryVariables: queryVariables,
            forceRefresh: true,
        });
        if (response) {
            yield put(getProSessionsSuccessAction(response));
        }
    }
    catch (error) {
        yield put(getProSessionsErrorAction(error));
        console.error('Failed to fetch session details.', error);
    }
}
export function* watchGetSessions() {
    yield takeLatest(getProSessionsAction.type, getSessionDetails);
}
export function* consoleSessionsSaga() {
    yield all([watchGetSessions()]);
}
